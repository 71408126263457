// React imports
import React, { useState } from "react";
import { useSelector } from "react-redux";

// Image imports
import boisRewards from "../../../../assets/images/bois-rewards.png";
import boisBuildEm from "../../../../assets/images/bois-build-em.png";

// Shared imports
import ALink from "../../../shared/ALink";
import FunctionalText from "../../../shared/FunctionalText";

// Local imports
import ProjectSection from "../ProjectSection";
import ProjectImage from "../ProjectImage";
import ImageCaption from "../ImageCaption";
import ProjectSectionUlRenderer from "../ProjectSectionUlRenderer";
import SampleBattleField from "./SampleBattleField";

// Helpers
import scrollToElement from "../../../../helpers/scrollToElement";

const Difficulties = () => {
  const settings = useSelector((state) => state.settings);
  const [image1IsLoaded, setImage1IsLoaded] = useState(false);
  const [image2IsLoaded, setImage2IsLoaded] = useState(false);
  return (
    <ProjectSection title="Difficulties">
      <p>
        There were, essentially, five <strong>Problems</strong> to tackle:
      </p>
      <ol>
        <li>
          <FunctionalText
            content="Storing data for users"
            title="Scroll to Problem 1"
            label="Scroll to Problem 1"
            onClick={() => {
              scrollToElement({
                elementId: "Problem-1",
                windowWidth: settings.windowWidth,
              });
            }}
          />
        </li>
        <li>
          <FunctionalText
            content="Designing levels"
            title="Scroll to Problem 2"
            label="Scroll to Problem 2"
            onClick={() => {
              scrollToElement({
                elementId: "Problem-2",
                windowWidth: settings.windowWidth,
              });
            }}
          />
        </li>
        <li>
          <FunctionalText
            content="Rewarding progression"
            title="Scroll to Problem 3"
            label="Scroll to Problem 3"
            onClick={() => {
              scrollToElement({
                elementId: "Problem-3",
                windowWidth: settings.windowWidth,
              });
            }}
          />
        </li>
        <li>
          <FunctionalText
            content="Building the bots"
            title="Scroll to Problem 4"
            label="Scroll to Problem 4"
            onClick={() => {
              scrollToElement({
                elementId: "Problem-4",
                windowWidth: settings.windowWidth,
              });
            }}
          />
        </li>
        <li>
          <FunctionalText
            content="Executing combat"
            title="Scroll to Problem 5"
            label="Scroll to Problem 5"
            onClick={() => {
              scrollToElement({
                elementId: "Problem-5",
                windowWidth: settings.windowWidth,
              });
            }}
          />
        </li>
      </ol>
      <p>
        <i>
          The order I worked on these was actually: 1,4,2,3,5. I re-ordered them
          here based on increasing complexity, as 4 and 5 were, by far, the most
          difficult.
        </i>
      </p>
      <p id="Problem-1">
        <strong>Problem 1</strong> could be achieved via user accounts on a
        database or just by local storage. Local storage has the advantage of
        not requiring database management, which would mean no communications to
        a server (other than serving the static page). That's a pretty massive
        pro, but if this project would ever achieve user pvp, well this would
        not be viable.
      </p>
      <p className="noBottomMargin">
        I used Auth0 to let people log in via google. It was was nice and quick,
        but I didn't want to exclude anyone, so I also built a log in method
        that could use any email address. I stored user objects in MongoDB
        (opting for NoSQL as my user objects would be complicated and I still
        didn't know their structure). You can see a quick log in demonstration{" "}
        <ALink
          href="https://www.youtube.com/watch?v=tpb4QPAmDto"
          newTab={true}
          label="link to youtube.com/watch?v=tpb4QPAmDto"
          content="here"
          // paddingLeft="10px"
        />
        . I further fleshed user information that included the following:
      </p>
      <ProjectSectionUlRenderer
        list={[
          "bot AIs",
          "in-game progress",
          "in-game unlocks",
          "color theme",
          "volume settings",
          "profile picture",
        ]}
      />
      <p>
        <i>
          I actually made videos during my progression of the project, and
          uploaded them onto youtube. You can see those lists{" "}
          <ALink
            href="https://www.youtube.com/channel/UCdCO3lCleXs3e9sQcAed3gw/playlists"
            newTab={true}
            label="link to youtube.com/channel/UCdCO3lCleXs3e9sQcAed3gw/playlists"
            content="here"
            // paddingLeft="10px"
          />
          .
        </i>
      </p>
      <br />
      <p id="Problem-2">
        <strong>Problem 2: designing levels</strong>, was pretty straight
        forward as designing a level only involves setting up the initial
        location of bots, the size of the battlefield and the rewards. The most
        interesting part of this would be designing levels in a way that teaches
        the player how to overcome obstacles in a progressive manner.
      </p>
      <p>So for example, level one looks something like this:</p>
      <SampleBattleField level={1} />
      <p>
        In the first two levels, the enemy bots are immobile punching bags.
        There are many ways to fight in the game, but bots react based on
        limited information (they only know what is next to them and what they
        have locked onto with an aim action). So a user can code their bot to
        look around with aim actions and shoot once they spot something.
        Alternatively, they can move about the map, trying to become adjacent to
        a bot, only to ram it or use melee attacks. With this in mind let's look
        at level 2:
      </p>
      <SampleBattleField level={2} />
      <p>
        Some levels will contain allies, and others will contain more than one
        user bot. So if a player attacked any bot they gained awareness of, they
        may well be destroying a friendly unit. Level 2 is intended to teach
        that to a player, so they keep in mind that they need to test if a found
        bot is an enemy.
      </p>
      <p id="Problem-3">
        <strong>Problem 3: rewarding progression</strong>, would be a matter of
        testing new accomplishments and verifying them against level dependant
        lists of requirements. If a new requirement was hit, I could send an
        update to the database to provide the user with the reward (often
        unlocking the next level, equipment or cosmetics).
      </p>
      <ProjectImage
        imageIsLoaded={image1IsLoaded}
        setImageIsLoaded={setImage1IsLoaded}
        src={boisRewards}
        alt="History of level achievements from deezBotBois"
      />
      <ImageCaption>
        Above: The level history UI which shows records and which rewards were
        unlocked (In this case Overkill).
      </ImageCaption>
      <p id="Problem-4">
        <strong>Problem 4: Building the bots</strong>, may have been the most
        difficult in theory. Users would have many selections to make: model,
        colors, name, equipment, skills tree, and the most complicated: the ai.
        I completed a UI (
        <ALink
          href="https://en.wikipedia.org/wiki/User_interface"
          newTab={true}
          label="link to en.wikipedia.org/wiki/User_interface"
          content="User Interface"
        />
        ) for those selections with collapsable tabs:
      </p>
      <ProjectImage
        imageIsLoaded={image2IsLoaded}
        setImageIsLoaded={setImage2IsLoaded}
        src={boisBuildEm}
        alt="A Build-em screen from deezBotBois"
      />
      <ImageCaption>
        Above: The build-em UI displaying the first four tabs.
      </ImageCaption>
      <p>
        Many of the symbols and buttons have mouse over or on-click effects that
        show pop ups explaining what they are - because there is a ton going on
        here. In fact while building this I realized I would need to make an
        encyclopedic glossary of definitions and information to help users
        understand the mechanics. And this wasn't the hard part - the ai is:
      </p>
      <p>
        When a programmer writes code, they do it in a series of nested tests
        and actions to take when certain conditions are met. The massive issue
        here is making a UI that non-programmers can use to nest conditions and
        actions. Not only that, they need to be able to move conditions around
        in the event that they made a mistake. Actions also need to be legal
        (for example you can't fire a weapon you're not holding), which sounds
        hilarious, but if a user changes their bots equipment after coding their
        ai, suddenly this becomes a possibility...
      </p>
      <p>
        <i>
          I'll come back to this in the{" "}
          <FunctionalText
            content="Resolution"
            title="Scroll to AI conflict resolution section"
            label="Scroll to AI conflict resolution section"
            onClick={() => {
              scrollToElement({
                elementId: "ai-conflict-resolution",
                windowWidth: settings.windowWidth,
              });
            }}
          />{" "}
          section below.
        </i>
      </p>
      <p id="Problem-5">
        <strong>Problem 5: executing combat</strong>, is where the bulk of my
        own code would be as it would be the meat of the operations. I would
        need to run the code of each bot turn by turn, figure out what action
        each wants to perform, manage their attribute changes and combat
        animations, test for game conditions, etc. It would prove to be a ton of
        calculations and nested functions...
      </p>
    </ProjectSection>
  );
};
export default Difficulties;
