import React from "react";
import SpaceBG from "./Components/SpaceBG";
import LargeDarkSpacerToAvoidWhiteWhenScrolling from "./Components/LargeDarkSpacerToAvoidWhiteWhenScrolling"
import NavBar from "./Components/NavBar/index";
import BodyGenerator from "./Components/BodyGenerator";

// hooks
import useMountAndWelcome from "./hooks/useMountAndWelcome";
import useScreenSizeTracker from "./hooks/useScreenSizeTracker";
import useFaviconModifier from "./hooks/useFaviconModifier";
import useURLController from "./hooks/useURLController";
import useSavedPreferences from "./hooks/useSavedPreferences";
import PowerButtonAndBottomBorder from "./Components/PowerButtonAndBottomBorder";

const Portfolio = () => {
	const hasMounted = useMountAndWelcome();
	useScreenSizeTracker();
	useFaviconModifier();
	const { scaledOpacity, setScaledOpacity, initialPath } = useURLController();
	useSavedPreferences();

	return (
		<>
			{hasMounted && <SpaceBG />}
			<LargeDarkSpacerToAvoidWhiteWhenScrolling />
			{initialPath && <NavBar />}
			{initialPath && <BodyGenerator initialPath={initialPath} scaledOpacity={scaledOpacity} setScaledOpacity={setScaledOpacity}/>}
			{initialPath && <PowerButtonAndBottomBorder/>}
		</>
	);
}

export default Portfolio;
