export default {
  viewBox: "0 0 64 64",
  children: [
    {
      name: "rect",
      attribs: {
        x: "1",
        y: "1",
        fill: "none",
        stroke: "#000000",
        "stroke-width": "2",
        "stroke-miterlimit": "10",
        width: "20",
        height: "20",
      },
      children: [],
    },
    {
      name: "rect",
      attribs: {
        x: "1",
        y: "21",
        fill: "none",
        stroke: "#000000",
        "stroke-width": "2",
        "stroke-miterlimit": "10",
        width: "20",
        height: "20",
      },
      children: [],
    },
    {
      name: "rect",
      attribs: {
        x: "21",
        y: "21",
        fill: "none",
        stroke: "#000000",
        "stroke-width": "2",
        "stroke-miterlimit": "10",
        width: "20",
        height: "20",
      },
      children: [],
    },
    {
      name: "rect",
      attribs: {
        x: "42",
        y: "21",
        fill: "none",
        stroke: "#000000",
        "stroke-width": "2",
        "stroke-miterlimit": "10",
        width: "20",
        height: "20",
      },
      children: [],
    },
		{
      name: "rect",
      attribs: {
        x: "21",
        y: "42",
        fill: "none",
        stroke: "#000000",
        "stroke-width": "2",
        "stroke-miterlimit": "10",
        width: "20",
        height: "20",
      },
      children: [],
    },
  ],
};
