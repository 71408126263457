import React from "react";
import styled from "styled-components";
import transitionTime from "../../constants/transitionTime";

const TransitionContainer = ({
  children,
  scaledOpacity,
  noTransition,
  altId,
}) => {
  return (
    <Container
      id={altId || "transition-container"}
      scaledOpacity={scaledOpacity}
      noTransition={noTransition}
    >
      {children}
    </Container>
  );
};
export default TransitionContainer;

const Container = styled.div`
  width: 100%;
  transition: opacity ${({noTransition}) => noTransition ? 0 :transitionTime}ms ease-in-out;
  opacity: ${(props) => props.scaledOpacity};
`;
