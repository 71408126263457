// React and State Components
import React from "react";
import { useSelector } from "react-redux";

// UI Components
import styled from "styled-components";

const Screws = ({ scale, baseWidth }) => {
	const settings = useSelector((state) => state.settings);
  const indentValue = "5%";
  const locations = [
    { top: indentValue, left: indentValue },
    { top: indentValue, right: indentValue },
    { bottom: indentValue, right: indentValue },
    { bottom: indentValue, left: indentValue },
    { bottom: "15%", left: "48%" },
  ];
  return (
    <>
      {locations.map((location, index) => {
        return (
          <ScrewPlacer
            className="centered"
            key={index}
            top={location.top}
            bottom={location.bottom}
            left={location.left}
            right={location.right}
            scale={scale}
            baseWidth={baseWidth}
						isPowered={settings.homePowerIsOn}
          >
            ✚
          </ScrewPlacer>
        );
      })}
    </>
  );
};

const ScrewPlacer = styled.div`
  position: absolute;
  border-radius: 50px;
  height: ${(props) => 0.0375 * props.baseWidth * props.scale}px;
  width: ${(props) => 0.0375 * props.baseWidth * props.scale}px;
  top: ${(props) => props.top};
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  opacity: 0.5;
  background-color: var(--blue);
  border: ${(props) =>
    `${0.005 * props.baseWidth * props.scale}px solid var(--blue-dark)`};
  color: var(--screw);
  font-size: clamp(5px, 2vw, 20px);
	-webkit-background-clip: ${(props) => props.isPowered && "border-box"};
	background-clip: ${(props) => props.isPowered && "border-box"};
  -webkit-text-fill-color: ${(props) => props.isPowered && "var(--screw)"};
`;

export default Screws;
