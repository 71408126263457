import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled, { keyframes } from "styled-components";
import {
	// setCallToDisplay,
	toggleHomePower,
} from "../../redux/actions";
import popUpTextOnElement from "../../helpers/popUpTextOnElement";

const FULL_ANIMATION_TIME = 1000;

const popPowerBar = keyframes`
	0% {
	  stroke: black;
	  transform: scaleY(0);
	}
	50% {
	  stroke: var(--power3);
	  transform: scaleY(1.3);
	}
	75% {
	  stroke: black;
	  transform: scaleY(1);
	}
	100% {
	  stroke: black;
	  transform: scaleY(1);
	}
`
const focusedStrokeShift = keyframes`
  0% {
		stroke: var(--hover);
    filter: hue-rotate(0deg);
  }
  100% {
		stroke: var(--hover);
    filter: hue-rotate(360deg);
  }
`
const invitingBar = keyframes`
  0% {
		transform: scaleY(.25);
  }
  50% {
		transform: scaleY(1);
  }
	100% {
		transform: scaleY(.25);
  }
`
const colorNub = keyframes`
	0% {
		stroke: black;
  }
	10% {
		stroke: var(--power2);
  }
	100% {
		stroke: var(--power2);
  }
`
const electricText = [
	// "⚡",
	// "🗲", // not working on tested devices
	"˗ˏˋ⚡︎ˎˊ˗",
	"ˋ⚡︎ˎ",
	"⚡︎"
];
const powerButtonId = "power-button";

const createNElectricPopUps = (numberOfPopUps = 5) => {
	for (let n = 0; n < numberOfPopUps; n++) {
		popUpTextOnElement({
			elementId: powerButtonId,
			desiredCharacter: electricText[Math.floor(Math.random()*electricText.length)],
			desiredColor: "yellow",
			forcedXMovement: Math.random()*-100,
			forcedYMovement: Math.random()*-100,
			zIndex: 9009,
		})
	}
}

let joshuaHasBeenThanked = false;


const PowerButtonAndBottomBorder = () => {
	const [booped, setBooped] = useState(false);
	const { homePowerIsOn } = useSelector((state) => state.settings);

	const dispatch = useDispatch();

	useEffect(() => {
		if (!booped) return;
		if (!joshuaHasBeenThanked) {
			console.log("Boop - thank you Joshua Comeau! https://www.joshwcomeau.com/react/boop/")
			joshuaHasBeenThanked = true;
		}
		
		const timer = setTimeout(() => {
			setBooped(false)
		}, FULL_ANIMATION_TIME)
		return (() => {
			clearTimeout(timer);
		})
	}, [booped]);

	let barClassName = "";
	if (booped) barClassName += "popped "
	if (homePowerIsOn) barClassName += "powered";

	return (
		<Container>
			<PowerButton className="centered" id={powerButtonId}
				type="button"
				onClick={() => {
					!homePowerIsOn && setTimeout(createNElectricPopUps,1);
					dispatch(toggleHomePower());
				}}
				onMouseEnter={() => { if (!homePowerIsOn) setBooped(true) }}>
				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
					<rect width="16" height="10" x="2" y="7" rx="2" ry="2" />
					<Nub className={barClassName} x1="22" x2="22" y1="11" y2="13" />
					<PowerBar $barNumber={0} className={barClassName+" inviting-bar"} x1="6" x2="6" y1="10" y2="14" />
					<PowerBar $barNumber={1} className={barClassName} x1="10" x2="10" y1="10" y2="14" />
					<PowerBar $barNumber={2} className={barClassName} x1="14" x2="14" y1="10" y2="14" />
				</svg>
			</PowerButton>
		</Container>
	)
}

export default PowerButtonAndBottomBorder

const Container = styled.div`
  position: fixed;
  bottom: -3px;
  border: var(--border-size) solid white;
  z-index: 100;
  width: 100%;
	backface-visibility: hidden;
	& * {
		backface-visibility: hidden;
	}
`

const PowerButton = styled.button`
  background-color: white;
	transform-origin: bottom right;
	scale: 0.75;
	min-height: 40px;
  right: 0;
  bottom: 0;
  position: absolute;
  border: none;
  border-radius: 50% 0 0 0;
	cursor: pointer;
	@media screen and (min-width: 500px) {
		scale: 1;
  }
	& svg {
    transition: all ease-in-out .1s;
    transfor-origin: center;
  	transform: rotate(-90deg) scale(1.5);
	}
	&:focus {
		outline: none;
	}
	&:hover .inviting-bar {
		animation: none;
		transform: scaleY(1);
	}
	&:hover svg rect {
		stroke: var(--hover);
	}
	&:focus svg rect {
		animation: ${focusedStrokeShift} infinite 8s;
	}
	&:active svg {
		transform: rotate(-90deg) scale(1.35);
	}
`

const PowerBar = styled.line`
	transition: all ease-in-out .3s;
	transform-origin: center;
  	transform: scaleY(${({ $barNumber }) => $barNumber === 0 ? ".25" : "0"});
  	stroke: var(--power3);
	&:not(.powered) {
		animation: ${invitingBar} infinite ${FULL_ANIMATION_TIME * 2}ms;
		animation: ${({ $barNumber }) => $barNumber !== 0 && "none"};
	}
	&.popped:not(.powered) {
		animation: ${popPowerBar} ${FULL_ANIMATION_TIME}ms ease-out ${({ $barNumber }) => {
		return $barNumber * FULL_ANIMATION_TIME / 8
	}}ms;
	}
	&.powered {
		transition: none;
		transform: scaleY(1);
		stroke: var(--power3);
	}
`
const Nub = styled.line`
	transform-origin: center;
	&.powered {
		stroke: var(--power3);
	}
	&.popped:not(.powered) {
		animation: ${colorNub} ${FULL_ANIMATION_TIME}ms ease-out ${FULL_ANIMATION_TIME * .5}ms;
	}
`