// React and State Components
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const LoadingBar = () => {
  const settings = useSelector((state) => state.settings);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    function incrementProgress() {
      setProgress((currentProgress) => {
        if (currentProgress < 150) {
          return currentProgress + 1;
        } else return 0;
      });
    }
    const incrementationInterval = setInterval(incrementProgress, 20);
    return () => {
      clearInterval(incrementationInterval);
    };
  }, []);

  return (
    <Wrapper>
      {progress >= 100 && (
        <Success isPowered={settings.homePowerIsOn} className="centered">
          UPLOAD COMPLETE
        </Success>
      )}
      {progress < 100 && (
        <CompletedOverlay progress={progress < 100 ? progress : 100} />
      )}
      {progress < 100 && (
        <InCompletedOverlay progress={progress < 100 ? progress : 100} />
      )}
    </Wrapper>
  );
};

export default LoadingBar;

const CompletedOverlay = styled.div`
  height: 18px;
  width: ${(props) => `${props.progress}%`};
  background-image: linear-gradient(
    ${`var(--hot-pink-dark)`},
    ${`var(--hot-pink)`},
    ${`var(--hot-pink-dark)`}
  );
  border: 3px solid var(--blue-light);
  border-right: none;
`;

const InCompletedOverlay = styled.div`
  height: 18px;
  width: ${(props) => `${100 - props.progress}%`};
  background-color: var(--baby-blue);
  border: 3px solid var(--blue-light);
  border-left: none;
`;

const Wrapper = styled.div`
  display: flex;
  height: 18px;
  width: 50%;
  max-width: 300px;
  transform: skew(-30deg);
`;
const Success = styled.div`
  height: 18px;
  width: 100%;
  transform: skew(30deg);
  /* color: var(--hot-pink); */
  -webkit-background-clip: ${(props) => props.isPowered && "border-box"};
  background-clip: ${(props) => props.isPowered && "border-box"};
  -webkit-text-fill-color: ${(props) => props.isPowered && "var(--hot-pink)"};
	animation: syncroSuccessColor 0.75s;
`;
