import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const ThoughtComponent = ({children}) => {
  const settings = useSelector((state) => state.settings);

  return (
		<Thought dark = {settings.dark}>
			{children}
		</Thought>
	)
};
export default ThoughtComponent;

const Thought = styled.i`
  font-style: oblique 50deg;
  color: ${(props) =>
    props.dark ? "var(--thought-dark)" : "var(--thought-light)"};
`;
