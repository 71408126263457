function popUpTextOnElement({
  elementId,
  desiredCharacter,
  desiredColor,
  preventTilt,
	forcedXMovement,
  forcedYMovement,
  zIndex
}) {
  if (!elementId) {
    console.log("error - no elementId provided to popUpTextOnElement function");
    return;
  }
  let color = desiredColor;
  if (!color) {
    let colors = ["red", "yellow", "lime", "cyan", "magenta", "hotpink"];
    const randomIndex = Math.floor(Math.random() * colors.length);
    color = colors[randomIndex];
  }
  let character = desiredCharacter;
  if (!character) {
    character = "!";
  }
  const targetElement = document.getElementById(elementId);
  if (!targetElement) {
    console.log(`Failed to grab ${elementId} by Id`);
    return;
  }
  // const rect = targetElement.getClientRects();
  // let x = rect[0].x + rect[0].width / 2;
  // let y = rect[0].y + rect[0].height / 2 - 12;
  const bubble = document.createElement("span");
  bubble.innerHTML = character;
  bubble.style.position = "absolute";
  // bubble.style.left = `${x}px`;
  // bubble.style.top = `${y}px`;
  // bubble.style.left = "50%";
  bubble.style.pointerEvents = "none";
  bubble.style.userSelect = "none";
  bubble.style.margin = "0";
  bubble.style.zIndex = zIndex || 15;
  bubble.style.color = color;
  bubble.style["-webkit-text-fill-color"] = color;
  bubble.style.fontWeight = "900";
  bubble.style.transition = "transform 1s cubic-bezier( 0, 1, 0.95, 0.99 )";
  targetElement.appendChild(bubble);
  const translateAndRotate = `translate(${forcedXMovement ? forcedXMovement : (Math.random() - 0.5) * 100}px, ${forcedYMovement ? forcedYMovement : -50}px) rotate(${preventTilt ? 0 : (Math.random() - 0.5) * 90}deg)`;
  setTimeout(() => {
    bubble.style.transform = `${translateAndRotate} scale(1)`;
  }, 10);
  setTimeout(() => {
    bubble.style.transform = `${translateAndRotate} scale(0)`;
    bubble.style.transition = "transform 0.25s ease-in";
  }, 950);
  setTimeout(() => {
    targetElement.removeChild(bubble);
  }, 1200);
}
export default popUpTextOnElement;
