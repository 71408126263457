import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './redux/store';

// Components
import Portfolio from './Portfolio';

// Global Styles
import "./index.css"

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <Portfolio />
	</Provider>,
  document.getElementById('root')
);
