import styled from "styled-components";
import { useSelector } from "react-redux";

const About = () => {
  const settings = useSelector((state) => state.settings);
  return (
    <Container className="centered col" isPowered={settings.homePowerIsOn}>
      <p>My young children (2 and 4 when I made this) were having difficulty with grasping the controls of video games.  So much so that I decided to make a very simple game for them.</p>
			<p>a-maze-me is that simple maze game!  You control a square with "WASD" keyboard buttons, and you must navigate your way through mazes to the circle. That's about it.</p>
			<p>There is no real introduction or explanation to the game.  I just wanted to make something I could have my children play where they didn't panic when an enemy approached them XD</p>
			<p>Took me just shy of 40 hours to make.  I've considered making it playable with touch screens, adding sound and levels where you need to collected ordered numbers or letters...  Maybe one day.</p>
			<p>I hope you, or your young ones, enjoy {"<"}3</p>
    </Container>
  );
};

export default About;

const Container = styled.section`
  flex-direction: column;
  p {
    width: 100%;
    color: ${(props) => (props.isPowered ? `var(--power2)` : `white`)};
    max-width: 45ch;
    text-align: left;
  }
  p:last-of-type {
    margin-bottom: 3em;
    width: fit-content;
  }
`;
