import React from "react";
import styled from "styled-components";

const ImageCaption = ({ marginTop, children }) => {
  return <Container marginTop={marginTop}>{children}</Container>;
};
export default ImageCaption;

const Container = styled.i`
  width: 90%;
  max-width: 650px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
  margin-top: ${(p) => (p.marginTop ? p.marginTop : 0)};
  font-size: 0.85rem;
  @media screen and (min-width: 1400px) {
    width: 75%;
  }
`;
