import React, {useState} from "react";
import styled from "styled-components";
// import NeonText from "../shared/NeonText";
import AndrewEasterEgg2 from "./AndrewEasterEgg2";
import SpecialText from "./SpecialText";

const Header = () => {
	const [rainbow, setRainbow] = useState(false)

  return (
    <Container className="columnFlexStart" id="resume-header">
      {/* <NeonText
        content="Andrew Diles"
        classNames="largeScalingText"
        containerClassNames="centered fullWidth"
        as="h2"
      /> */}
			<AndrewEasterEgg2 setRainbow = {setRainbow}/>
      <SpecialText text="Web Developer" rainbow={rainbow} />
    </Container>
  );
};
export default Header;

const Container = styled.section`
  width: 100%;
`;
