import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import useHideIfIdPresent from "../../../hooks/useHideIfIdPresent"

// Shared imports
import IconButton from "../../shared/IconButton";
import FunctionalText from "../../shared/FunctionalText";

// Helpers
import scrollToElement from "../../../helpers/scrollToElement";

// Icons imports
import { listOrdered } from "react-icons-kit/oct/listOrdered";
import { x } from "react-icons-kit/oct/x";

const CollapsableContentNavigation = ({ contentList }) => {
  const [listIsExpanded, setListIsExpanded] = useState(false);
  const hide = useHideIfIdPresent("mobile-nav-container");
  const settings = useSelector((state) => state.settings);

  return (
    <Container
      listIsExpanded={listIsExpanded}
      hide={hide}
      // borderColor={settings.homePowerIsOn ? "var(--power2)" : "white"}
      borderColor="white"
      backgroundColor={
        settings.homePowerIsOn ? "var(--bg1-dark)" : "var(--bg2-dark)"
      }
    >
      <IconButton
        handleClick={() => {
          setListIsExpanded(!listIsExpanded);
        }}
        icon={listIsExpanded ? x : listOrdered}
        label={
          listIsExpanded
            ? "Collapse Content Navigation Button"
            : "Expand Content Navigation Button"
        }
      />
      {listIsExpanded && (
        <ContentP color={settings.homePowerIsOn ? "var(--power2)" : "inherit"}>
          <strong>Content</strong>
        </ContentP>
      )}
      {listIsExpanded &&
        contentList.map((content, index) => {
          return (
            <FunctionalText
              key={index}
              content={content}
              label={`Scroll to ${content} section`}
              onClick={() => {
                scrollToElement({
                  elementId: content,
                  windowWidth: settings.windowWidth,
                  additionalModifier: 32,
                });
              }}
            />
          );
        })}
    </Container>
  );
};
export default CollapsableContentNavigation;
const Container = styled.div`
  /* height: 50px; */
  /* width: 50px; */
  display: ${({hide}) => hide ? "none":"flex"};
  justify-content: flex-end;
  align-items: flex-end;
  text-align: center;
  position: fixed;
  flex-direction: column;
  top: 90px;
  // If not using <Fader, top should be 20 less (70)
  right: 0;
  margin-right: calc(var(--border-size) - 4px);
  z-index: 9001;
  outline: ${(p) => p.listIsExpanded && `4px solid ${p.borderColor}`};
  /* background-color: ${(p) =>
    p.listIsExpanded ? `${p.backgroundColor}` : "inherit"}; */
  background-color: ${(p) => p.listIsExpanded && `${p.backgroundColor}`};
  padding: ${(p) => p.listIsExpanded && "0 8px 8px"};
  /* var(--power2) */
  @media screen and (min-width: 500px) {
    top: 155px;
    // If not using <Fader, top should be 40 less (115)
  }
  @media screen and (min-width: 1092px) {
    top: 165px;
    right: ${(p) =>
    p.listIsExpanded ? "calc(50vw - 558px)" : "calc(50vw - 550px)"};
    padding: ${(p) => p.listIsExpanded && "0 8px 8px"};
  }
  @media screen and (min-width: 1400px) {
    align-items: flex-start;
    right: ${(p) => p.listIsExpanded && "auto"};
    left: ${(p) => p.listIsExpanded && "calc(50vw + 510px)"};
  }
  p + span {
    padding-top: 0.25rem;
    @media screen and (min-width: 500px) {
      padding-top: 0.5rem;
    }
  }
`;
const ContentP = styled.p`
  margin: 0;
  padding-bottom: 0.25rem;
  text-align: end;
  border-bottom: 2px dashed;
  color: ${(p) => p.color && p.color};
  @media screen and (min-width: 500px) {
    padding-bottom: 0.5rem;
  }
  @media screen and (min-width: 1400px) {
    text-align: start;
  }
`;
