// React imports
import React from "react";

// Shared imports
import ALink from "../../../shared/ALink";
import Emoji from "../../../shared/Emoji";

// Local imports
import ProjectSection from "../ProjectSection";

const Motivation = () => {
  return (
    <ProjectSection title="Motivation">
      <p>
        Well... I wanted to make an app that could solve Sudoku puzzles{" "}
        <Emoji>😃</Emoji>
      </p>
      <p>
        Why? When I was younger, I used to solve Sudoku puzzles. My father and I
        would take stabs at the hardest problems available in books. However, I
        would always feel frustrated when a problem had, effectively, run out of
        logical progression, and the next move was to guess at a number and hope
        for a fast contradiction four to six steps later so you could negate its
        possibility.
      </p>
      <p>
        I figured if I could build algorithms that could solve any problem, then
        I could, effectively, say manually solving future problems would just be
        a matter of going through the motions of inevitability.
      </p>
      <p>
        While studying at McGill, I started a solver in C++, but my limited
        graphical capacities discouraged me from continuing, as inputting
        numbers felt a chore.
      </p>
      <p>
        Fast forward to my time at Concordia studying full stack development: a
        couple of days after starting to learn React, the school shut down for
        two weeks due to Covid. During this time, the course was put on hiatus.
        So I decided to restart the old project, using the component driven
        library I had just been exposed to.
      </p>
      <p>
        <strong>Addendum</strong>: If you're not familiar with Sudoku puzzles,
        then you can find an explanation of the game{" "}
        <ALink
          href="http://www.sudoku-space.com/sudoku.php"
          newTab={true}
          label="link to sudoku-space.com"
          content="here"
        />
        .
      </p>
    </ProjectSection>
  );
};
export default Motivation;
