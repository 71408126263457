import React from "react";
import styled from "styled-components";
import SectionContainer from "../SectionContainer";
import listOfSkills from "./listOfSkills";
import Skill from "./Skill";

const Skills = () => {
  return (
    <SectionContainer title="Skills">
      {/* <P
        dark={settings.dark}
        isPowered={settings.homePowerIsOn}
        className="noTopMargin scalingBottomMargin"
      >
        Over the years I've used many coding languages. Recently, I've honed
        the following skills to the ends of creating interactive web
        applications.
      </P> */}
      <SkillsContainer
        // className="noTopMargin scalingBottomMargin"
        className="scalingBottomPadding"
      >
        {listOfSkills.map((skillInfo, index) => {
          return (
            <Skill
              key={index}
              skillName={skillInfo.name}
              href={skillInfo.href.length > 0 ? skillInfo.href:0}
            />
          );
        })}
      </SkillsContainer>
    </SectionContainer>
  );
};
export default Skills;

const SkillsContainer = styled.ul`
  margin: 0;
  padding-left: 0;
  display: grid;
  grid-template-columns: 1fr;
  @media screen and (min-width: 500px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (min-width: 1080px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
