// React imports
import React from "react";

// Shared imports
import Emoji from "../../../shared/Emoji";

// Local imports
import ProjectSection from "../ProjectSection";

const Difficulties = () => {
  return (
    <ProjectSection title="Difficulties">
      <p>
        The product worked - for simple puzzles... The algorithms ran until it
        solved or crashed. The problem was that on more difficult puzzles, the
        methods were not up to the task of finding a solution, so they ran
        forever until the browser crashed.
      </p>
      <p>
        I decided to make the process occur in steps of testing for new
        information, inserting the new information / updating the knowns and
        unknowns.{" "}
        <em>
          It also made development easier, as I could see when potential bugs
          occurred.
        </em>
      </p>
      <p>
        I implemented a test to see if new information was found, and if no
        information was found between a full loop of tests, then the solver
        stopped.
      </p>
      <p>
        Since harder puzzles required more methods to discover new information,
        I looked to how I solve these puzzles in real life, and turned the
        process of deduction into algorithms. Harder problems were then
        solvable, but still, only until a certain point.
      </p>
      <p>
        Around this point, I made buttons to fire off each individual algorithm,
        as some of the more rudimentary ones generally provided nothing new on
        difficult problems.
      </p>
      <p>
        When I inputted extremely difficult puzzles, even with my best
        deduction, there would eventually be no new obtainable information. I
        had pretty much reached my deductive capacities absent potential
        guess-work <Emoji>😒</Emoji>. So the next step was to begin guess and
        checks. This is effectively brute forcing the answer.
      </p>
    </ProjectSection>
  );
};
export default Difficulties;
