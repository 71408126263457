import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCallToDisplay } from "../../redux/actions";
import styled from "styled-components";
import NeonText from "../shared/NeonText";
import Interest from "../Resume/Skills/Skill";
import AngledButton from "../shared/AngledButton";

const listOfInterests = [
  "physics",
  "game design",
  "dungeons & dragons",
  "retro video games",
  "philosophy",
  "writing",
];

const OtherInterests = () => {
  const settings = useSelector((state) => state.settings);
  const dispatch = useDispatch();

  return (
    <Container className="scalingBottomMargin">
      <NeonText
        content="Other Interests"
        classNames="largeScalingText scalingBottomMargin"
      />
      <P
        dark={settings.dark}
        isPowered={settings.homePowerIsOn}
        className="noTopMargin scalingBottomMargin"
      >
        What else do I enjoy? Glad you asked!
      </P>
      <InterestsContainer className="noTopMargin scalingBottomMargin">
        {listOfInterests.map((interest, index) => {
          return <Interest key={index} skillName={interest} />;
        })}
      </InterestsContainer>
      <P
        dark={settings.dark}
        isPowered={settings.homePowerIsOn}
        className="noTopMargin scalingBottomMargin"
      >
        In fact, I've been working on a novel...
      </P>
      <ButtonPlacement>
        <AngledButton
          label="Read my first chapter button"
          innerText="Read chapter 1"
          title="Read my first chapter button"
          transformOrigin="center"
          handleClick={() => {
            dispatch(setCallToDisplay("CH 1"));
						window.history.pushState({}, "", "/chapter01")
          }}
        />
      </ButtonPlacement>
    </Container>
  );
};
export default OtherInterests;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 500px) {
    h3 {
      text-align: center;
    }
  }
`;

const P = styled.p`
  background: ${(props) =>
    !props.isPowered
      ? props.dark
        ? "var(--text-dark)"
        : "var(--text-light)"
      : props.dark
      ? "-webkit-linear-gradient(-45deg, var(--text-dark), var(--power2))"
      : "-webkit-linear-gradient(-45deg, var(--text-light), var(--power2))"};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const InterestsContainer = styled.ul`
  padding-left: 0;
  display: grid;
  grid-template-columns: 1fr;
  @media screen and (min-width: 600px) {
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const ButtonPlacement = styled.div`
  width: fit-content;
  margin: auto;
`;
