import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import transitionTime from "../../constants/transitionTime";
import {navicon as hamburger} from 'react-icons-kit/ionicons/navicon';
import IconButton from "../shared/IconButton";
import ExpandedMobileOptions from "./ExpandedMobileOptions";

const MobileNav = () => {
  const [expandedState, setExpandedState] = useState(false); // false "opening" true "closing"
  const settings = useSelector((state) => state.settings);

  let timer;
  useEffect(() => {
    if (settings.windowWidth >= 800 && expandedState !== false) {
      setExpandedState(false);
      clearTimeout(timer)
    }
  }, [settings.windowWidth])

  const showHamburger = (expandedState === false || expandedState === "closing");

  return (
    <Container showHamburger={showHamburger} transitionTime={transitionTime}>
      <IconButton
        icon={hamburger}
        noUnderlineOnFocus={true}
        sizeMin={50}
        size300={50}
        size500={60}
        size800={60}
        handleClick={() => {
          if (expandedState === false) {
            setExpandedState("opening");
            timer = setTimeout(() => {
              setExpandedState(true)
            }, transitionTime)
          } else if (expandedState === true) {
            setExpandedState("closing");
            timer = setTimeout(() => {
              setExpandedState(false)
            }, transitionTime)
          }
        }}
        label={`${(expandedState === false || expandedState === "closing") ? "Expand " : "Collapse "}Options`}
      />
      {expandedState !== false && <ExpandedMobileOptions expandedState={expandedState} setExpandedState={setExpandedState}/>}
    </Container>
  );
};
export default MobileNav;

const Container = styled.nav`
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding-right: 0;
  @media screen and (min-width: 500px) {
    padding-right: 14px;
  }
  @media screen and (min-width: 800px) {
    display: none;
  }

  & rect {
    transition: transform ${({transitionTime})=>transitionTime||500}ms ease-in-out;
    transform-origin: center;
  }

  // top bar
  & rect:nth-child(2) {
    transform: rotate(${({showHamburger}) => showHamburger ? "0":"45deg"}) translate(${({showHamburger}) => showHamburger ? "0 0":"0, 20%"}) scaleX(${({showHamburger}) => showHamburger ? "1":"1.25"});
  }

  // middle bar
  & rect:nth-child(1) {
    transform: scale(${({showHamburger}) => showHamburger ? "1":"0"});
  }

  // bottom bar
  & rect:nth-child(3) {
    transform: rotate(${({showHamburger}) => showHamburger ? "0":"-45deg"}) translate(${({showHamburger}) => showHamburger ? "0 0":"0, -18%"}) scaleX(${({showHamburger}) => showHamburger ? "1":"1.25"});
  }
`;