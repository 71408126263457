import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    shiftAdditionalNavOption,
    setCallToDisplay,
} from "../redux/actions";
import navInfo, { portfolioSubNav } from "../helpers/navInfo";
import scrollUp from "../helpers/scrollUp";

const baseOptions = navInfo.filter(option => option.baseOption && !option.fallback)
const additionalOptions = navInfo.filter(option => !option.baseOption)
const defaultOption = navInfo.find(option => option.baseOption)

const useURLController = () => {
    const [scaledOpacity, setScaledOpacity] = useState(0);
    const [initialPath, setInitialPath] = useState(null)
    const dispatch = useDispatch();
    const settings = useSelector((state) => state.settings);

    useEffect(() => {
        scrollUp()
        const casedLocation = window.location.pathname.toLowerCase();
        const foundBaseOption = baseOptions.find(option => option.path === casedLocation);
        if (foundBaseOption) {
            setInitialPath(foundBaseOption.path.substring(1));
            return dispatch(setCallToDisplay(foundBaseOption.navName));
        }

        const foundAdditionalOption = additionalOptions.find(option => option.path === casedLocation);
        if (foundAdditionalOption) {
            dispatch(shiftAdditionalNavOption(foundAdditionalOption.navName));
            dispatch(setCallToDisplay(foundAdditionalOption.navName));
            return setInitialPath(foundAdditionalOption.path.substring(1));
        }
        const foundPortfolioOption = portfolioSubNav.find(option => option.path === casedLocation);
        if (foundPortfolioOption) {
            setInitialPath(foundPortfolioOption.path.substring(1));
            return dispatch(setCallToDisplay(foundPortfolioOption.navName));
        }
        setInitialPath(defaultOption.altPath.substring(1));
        dispatch(setCallToDisplay("ABOUT"));
        window.history.pushState({}, "", "/");
    }, []);

    useEffect(()=>{

        const manageNavigation = () => {
            const casedLocation = window.location.pathname.toLowerCase();
            let foundOption = navInfo.find(option => option.path === casedLocation || option.altPath === casedLocation);

            if (!foundOption) {
                return console.log("ISSUE FINDING NAVIGATION OPTION")
            }

            // setScaledOpacity(0);
            dispatch(setCallToDisplay(foundOption.navName))
        }
        // manageNavigation()
        const handleHistoryChange = () => {
            // console.log("popping!");
            // console.log(window.location.pathname)
            manageNavigation()
            // console.log(window.history)
            // console.log(settings);
            // ev.preventDefault()
            // window.history.pushState(null, "", window.location.href)
            // dispatch(handleNavigateBack())
        }

        window.addEventListener(
            "popstate"
            , handleHistoryChange);

        return () => {
          window.removeEventListener("popstate", handleHistoryChange);
        };
    },[
        window.history
    ])

    return {scaledOpacity, setScaledOpacity, initialPath};
};
export default useURLController;
