export default {
  viewBox: "0 0 64 64",
  children: [
		// outside border
    {
      name: "rect",
      attribs: {
        x: "0",
        y: "0",
        fill: "none",
        stroke: "#000000",
        "stroke-width": "2",
        "stroke-miterlimit": "10",
        width: "64",
        height: "64",
      },
      children: [],
    },
		// top line
    {
      name: "rect",
      attribs: {
        x: "2",
        y: "14",
        fill: "none",
        stroke: "#000000",
        "stroke-width": "2",
        "stroke-miterlimit": "10",
        width: "24",
        height: "2",
      },
      children: [],
    },
		// 9'oclock box
    {
      name: "rect",
      attribs: {
        x: "0",
        y: "26",
        fill: "none",
        stroke: "#000000",
        "stroke-width": "4",
        "stroke-miterlimit": "10",
        width: "26",
        height: "12",
      },
      children: [],
    },
		// player
    {
      name: "rect",
      attribs: {
        x: "4",
        y: "52",
        fill: "red",
        stroke: "red",
        "stroke-width": "2",
        "stroke-miterlimit": "10",
        width: "8",
        height: "8",
      },
      children: [],
    },

		{
      name: "rect",
      attribs: {
        x: "14",
        y: "50",
        fill: "none",
        stroke: "#000000",
        "stroke-width": "2",
        "stroke-miterlimit": "10",
        width: "2",
        height: "12",
      },
      children: [],
    },
		{
      name: "rect",
      attribs: {
        x: "14",
        y: "50",
        fill: "none",
        stroke: "#000000",
        "stroke-width": "2",
        "stroke-miterlimit": "10",
        width: "12",
        height: "2",
      },
      children: [],
    },

		{
      name: "rect",
      attribs: {
        x: "38",
        y: "14",
        fill: "none",
        stroke: "#000000",
        "stroke-width": "2",
        "stroke-miterlimit": "10",
        width: "2",
        height: "36",
      },
      children: [],
    },
		{
      name: "rect",
      attribs: {
        x: "38",
        y: "50",
        fill: "none",
        stroke: "#000000",
        "stroke-width": "2",
        "stroke-miterlimit": "10",
        width: "12",
        height: "2",
      },
      children: [],
    },
		{
      name: "rect",
      attribs: {
        x: "50",
        y: "2",
        fill: "none",
        stroke: "#000000",
        "stroke-width": "2",
        "stroke-miterlimit": "10",
        width: "2",
        height: "48",
      },
      children: [],
    },
		{
      name: "circle",
      attribs: {
        cx: "58",
        cy: "6",
				r: "4",
        fill: "green",
        stroke: "green",
        "stroke-width": "2",
        "stroke-miterlimit": "10",
      },
      children: [],
    },
  ],
};
