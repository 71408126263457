import React from "react";
import IconButton from "../../shared/IconButton";
import { useDispatch } from "react-redux";
import { setCallToDisplay } from "../../../redux/actions";

// Icons imports
import { ic_directions_boat } from "react-icons-kit/md/ic_directions_boat";

const BattleshipEasterEgg = () => {
  const dispatch = useDispatch();
  return (
    <IconButton
      handleClick={() => {
        dispatch(setCallToDisplay("BS"));
        window.history.pushState({}, "", "/battle-ship")
      }}
      icon={ic_directions_boat}
      label="Battleship?"
    />
  );
};
export default BattleshipEasterEgg;