import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const NavButton = ({ optionName, handleClick, isDisabled, hideGlowing }) => {
  const settings = useSelector((state) => state.settings);

  return (
    <Container
      aria-label={`See ${optionName.toLowerCase()} button`}
      title={`See ${optionName.toLowerCase()} button`}
      onClick={() => {
        !isDisabled && handleClick();
      }}
			isDisabled = {isDisabled}
      id={`${optionName}-nav-button`}
      isPowered={settings.homePowerIsOn}
			className="nav-buttton"
    >
      {settings.homePowerIsOn && !hideGlowing && (
        <GlowingH3 className="GlowingH3">{optionName}</GlowingH3>
      )}
      <NonGlowingH3 isPowered={settings.homePowerIsOn} dark={settings.dark}>
        {optionName}
      </NonGlowingH3>
    </Container>
  );
};
export default NavButton;

const Container = styled.button`
  background: inherit;
  margin: 0 5px;
  border: none;
  @media screen and (min-width: 500px) {
    margin: 0 10px;
  }
  @media screen and (min-width: 800px) {
    margin: 0 20px;
  }
  :hover {
    cursor: ${p => p.isDisabled? "not-allowed" : "pointer"};
    > h3 {
      color: ${(props) => (props.isPowered ? "white" : "var(--hover)")};
    }
    h3.GlowingH3 {
      text-shadow: 0 0 7px #fff, 0 0 10px hsl(297, 100%, 95%),
        0 0 21px hsl(297, 100%, 75%), 0 0 42px hsl(297, 100%, 55%),
        0 0 60px hsl(297, 100%, 55%), 0 0 80px hsl(297, 100%, 55%);
    }
  }
  :focus {
    outline: none;
		animation: hueShift infinite 8s;
    > h3 {
      color: ${(props) => (props.isPowered ? "white" : "var(--focus)")};
			// text-decoration: underline;
    }
    h3.GlowingH3 {
      text-shadow: 0 0 7px #fff, 0 0 10px hsl(297, 100%, 75%),
        0 0 21px hsl(297, 100%, 55%), 0 0 42px hsl(297, 100%, 50%),
        0 0 60px hsl(297, 100%, 50%), 0 0 80px hsl(297, 100%, 50%),
        0 0 100px hsl(297, 100%, 50%), 0 0 120px hsl(297, 100%, 50%);
    }
  }
  :active {
    outline: none;
    transform: scale(0.95);
    h3 {
      transform: scale(0.95);
      color: ${(props) => (props.isPowered ? "white" : "var(--focus)")};
    }
    h3.GlowingH3 {
      text-shadow: 0 0 7px #fff, 0 0 10px hsl(297, 100%, 75%),
        0 0 21px hsl(297, 100%, 55%), 0 0 42px hsl(297, 100%, 50%),
        0 0 60px hsl(297, 100%, 50%), 0 0 80px hsl(297, 100%, 50%),
        0 0 100px hsl(297, 100%, 50%), 0 0 120px hsl(297, 100%, 50%);
    }
  }
  > h3 {
    font-family: Sans-Serif, Georgia;
    font-size: 8px;
    transition: transform 0.1s ease-in-out;
    pointer-events: none;
    @media screen and (min-width: 500px) {
      font-size: 14px;
    }
    @media screen and (min-width: 800px) {
      font-size: 18px;
    }
  }
`;

const NonGlowingH3 = styled.h3`
  color: ${(props) =>
    props.isPowered
      ? "white"
      : props.dark
      ? "var(--text-dark)"
      : "var(--text-light)"};
`;

const GlowingH3 = styled.h3`
  position: absolute;
  color: white;
  backface-visibility: hidden;
  animation: neonFadeIn ease infinite 1s alternate-reverse;
  text-shadow: 0 0 7px #fff, 0 0 10px hsl(180, 100%, 95%),
    0 0 21px hsl(200, 100%, 75%), 0 0 42px hsl(200, 100%, 55%),
    0 0 60px hsl(200, 100%, 55%), 0 0 80px hsl(200, 100%, 55%);
`;
