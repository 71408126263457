import React from "react";
import { useDispatch } from "react-redux";
import { setCallToDisplay } from "../../../redux/actions";
import styled from "styled-components";
import IconButton from "../../shared/IconButton";
import {cornerUpLeft} from 'react-icons-kit/feather/cornerUpLeft';
import useHideIfIdPresent from "../../../hooks/useHideIfIdPresent";

const ReturnToOverviewTopIcon = () => {
  const hide = useHideIfIdPresent("mobile-nav-container");
  const dispatch = useDispatch();
  
  return (
    <IconContainer hide={hide}>
      <IconButton
        handleClick={() => {
					window.history.pushState({}, "", `/portfolio`);
          dispatch(setCallToDisplay("PORTFOLIO"));
        }}
        icon={cornerUpLeft}
        label="Return to Projects Overview Button"
      />
    </IconContainer>
  );
};
export default ReturnToOverviewTopIcon;

const IconContainer = styled.div`
  z-index: 9001;
  /* height: 50px; */
  width: 50px;
  display: ${({hide})=>hide ? "none":"flex"};
  justify-content: flex-end;
  text-align: center;
  position: fixed;
  top: 55px;
  margin-right: var(--border-size);
	// If not using <Fader, top should be 20 less (35)
  right: 0;
  @media screen and (min-width: 500px) {
    top: 110px;
		// If not using <Fader, top should be 40 less (70)
  }
  @media screen and (min-width: 1092px) {
    right: calc(50vw - 550px);
  }
`;
