import styled from "styled-components";

import PentrisEasterEgg from "./easterEggLinks/PetrisEasterEgg";
import BattleshipEasterEgg from "./easterEggLinks/BattleshipEasterEgg";
import AMazeMeEasterEgg from "./easterEggLinks/AMazeMeEasterEgg";
import LearningMathGameEasterEgg from "./easterEggLinks/LearningMathGameEasterEgg";

const EasterEggFooter = () => {
  return (
    <Container>
      <RowContainer $bottom={false} className="centered"><PentrisEasterEgg /> <AMazeMeEasterEgg /></RowContainer>
      <RowContainer $bottom={false} className="centered"> <BattleshipEasterEgg /> <LearningMathGameEasterEgg /></RowContainer>
      <RowContainer $bottom={true} className="centered">  </RowContainer>
    </Container>
  );
};

export default EasterEggFooter;

const Container = styled.footer`
padding: 0 10px;
  @media screen and (min-width: 500px) {
    text-align: center;
    padding: 0 15px;
  }
  @media screen and (min-width: 800px) {
    padding: 0 20px;
  }
`

const RowContainer = styled.div`
  margin: 10px 0;
  justify-content: space-between;
  margin-bottom: ${({ $bottom }) => $bottom && "40px"};
`