import styled from "styled-components";
import { useSelector } from "react-redux";

const About = () => {
  const settings = useSelector((state) => state.settings);
  return (
    <Container className="centered col" isPowered={settings.homePowerIsOn}>
      <p>
        My children (4 and 6 when I made this) were playing a simple math game
        at the library, but the UI was unintuitive and full of clutter - so I
        decided to remake the game 🤣
      </p>
      <p>Learning-Math-Game is that very game! My children had a lot of fun playing it when I showed it to them a few days later.</p>
      <p>
        Being amused while learning is quite the trick, isn't it? I think the
        gamification of learning can make the process much more enjoyable for
        everyone 🍎
      </p>
      <p>
        The game itself is extremely simple. There are levels that ask randomly
        generated questions with multiple choice answers. Players must answer
        ten correct answers before making three mistakes (a lives system). I
        finished the first version in under six hours, but I continued to add
        more levels afterward.
      </p>
      <p>Feel free to take a look or let your children try it out!</p>
    </Container>
  );
};

export default About;

const Container = styled.section`
  flex-direction: column;
  p {
    width: 100%;
    color: ${(props) => (props.isPowered ? `var(--power2)` : `white`)};
    max-width: 45ch;
    text-align: left;
  }
  p:last-of-type {
    margin-bottom: 3em;
    width: fit-content;
  }
`;
