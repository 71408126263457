import React from "react";
import IconButton from "../../shared/IconButton";
import { useDispatch } from "react-redux";
import { setCallToDisplay } from "../../../redux/actions";

// Icons imports
import mazeIcon from "../../../assets/svgs/mazeIcon";

const AMazeMeEasterEgg = () => {
  const dispatch = useDispatch();
  return (
    <IconButton
      handleClick={() => {
        dispatch(setCallToDisplay("A-MAZE-ME"));
        window.history.pushState({}, "", "/a-maze-me")
      }}
      icon={mazeIcon}
      label="a-maze-me"
    />
  );
};
export default AMazeMeEasterEgg;