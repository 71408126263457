import React from "react";
import styled from "styled-components";

import AngledButton from "../../shared/AngledButton";
import createAFocusClickRemove from "../../../helpers/createAFocusClickRemove";

const PlayButton = () => {

  return (
    <ButtonContainer className="centered">
      <AngledButton
        className="centered"
        label="Play a math-game"
        innerText="Play Now"
        transformOrigin="center"
        handleClick={() => {
					createAFocusClickRemove("https://andrewdiles.github.io/learning-math-game/")
        }}
      />
    </ButtonContainer>
  );
};

export default PlayButton;

const ButtonContainer = styled.div`
  div {
    transform: scale(1.5);
    @media screen and (min-width: 500px) {
      transform: scale(2);
    }
    @media screen and (min-width: 800px) {
      transform: scale(2.5);
    }
  }
`;
