import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import pathInfo from "../../assets/svgs/buttonPaths";

const AngledButton = ({
  handleClick,
  innerText,
  label,
  title,
  fontSize,
  scaleMin,
  scale300,
  scale500,
  scale800,
  scale1000,
  transformOrigin,
  altButtonNumber,
  isNotSelected,
	disabled,
}) => {
  const settings = useSelector((state) => state.settings);
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isBeingClicked, setIsBeingClicked] = useState(false);

  function clickOnEnter(e) {
    if (e.code === "Enter") {
      const elem = document.getElementById(`${label}-element`);
      if (!elem) return;
      if (elem.matches(":focus")) {
        // console.log(
        //   `Enter pressed while focusing element of id ${label}-element, executing handleClick`
        // );
        if (handleClick && !disabled) {
					handleClick(e);
				}
      }
    }
  }
	function setIsBeingClickedFalse() {
		setIsBeingClicked(false);
	}

  useEffect(() => {
    // console.log(`FunctionText component of id ${label}-element was created`);
    window.addEventListener("keydown", clickOnEnter);
		window.addEventListener("mouseup", setIsBeingClickedFalse);
    return () => {
      window.removeEventListener("keydown", clickOnEnter);
			window.removeEventListener("mouseup", setIsBeingClickedFalse);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

	
	let filter = isNotSelected ? "opacity(50%)" : "opacity(100%)";
	disabled && (filter += " grayscale(.75)");
  return (
    <Container
      dark={settings.dark}
      scaleMin={scaleMin || 0.6}
      scale300={scale300 || 0.7}
      scale500={scale500 || 0.8}
      scale800={scale800 || 0.9}
      scale1000={scale1000 || 1}
      transformOrigin={transformOrigin}
      aria-label={label}
      title={title || label}
      isNotSelected={isNotSelected}
			filter = {filter}
    >
      <Svg
        id={`${label}-element`}
        tabIndex={disabled ? -1 : 0}
        className="centered"
        width="150"
        height="50"
        isPowered={settings.homePowerIsOn}
        isHovered={isHovered}
        isFocused={isFocused}
        isBeingClicked={isBeingClicked}
				disabled = {disabled}
        onMouseOver={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
          // setIsBeingClicked(false);
        }}
        onMouseDown={() => {
					if (disabled) return
          setIsBeingClicked(true);
        }}
        onMouseUp={() => {
          setIsBeingClicked(false);
        }}
        onClick={(e) => {
          if (handleClick && !disabled) {
            handleClick(e);
          }
        }}
        onFocus={() => {
          setIsFocused(true);
        }}
        onBlur={() => {
          setIsFocused(false);
          setIsBeingClicked(false);
        }}
      >
        <path
          style={{
            fill:
              isBeingClicked || isFocused
                ? "var(--focus)"
                : isHovered
                ? "var(--hover)"
                : settings.homePowerIsOn
                ? "var(--power3)"
                : settings.dark
                ? "var(--text-dark)"
                : "var(--text-light)",
            strokeWidth: 8,
          }}
          d={
            pathInfo[
              altButtonNumber ? altButtonNumber - 1 : settings.buttonNumber
            ][1]
          }
        />
        <path
          style={{
            fill:
              isBeingClicked || isFocused || isHovered
                ? settings.dark
                  ? "var(--bg2-dark)"
                  : "var(--bg2-light)"
                : settings.dark
                ? "var(--bg3-dark)"
                : "var(--bg3-light)",
            strokeWidth: 8,
          }}
          d={
            pathInfo[
              altButtonNumber ? altButtonNumber - 1 : settings.buttonNumber
            ][2]
          }
        />
      </Svg>
      <Label
        dark={settings.dark}
        fontSize={fontSize}
        isPowered={settings.homePowerIsOn}
        isHovered={isHovered}
        isFocused={isFocused}
        isBeingClicked={isBeingClicked}
        className="unselectable"
      >
        {innerText}
      </Label>
    </Container>
  );
};
export default AngledButton;

const Container = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: ${(props) => `scale(${props.scaleMin})`};
  transform-origin: ${(props) => props.transformOrigin};
  /* opacity: ${(p) => p.isNotSelected && 0.5}; */
	filter: ${(p) => p.filter};
  @media screen and (min-width: 300px) {
    transform: ${(props) => `scale(${props.scale300})`};
  }
  @media screen and (min-width: 500px) {
    transform: ${(props) => `scale(${props.scale500})`};
  }
  @media screen and (min-width: 800px) {
    transform: ${(props) => `scale(${props.scale800})`};
  }
  @media screen and (min-width: 1000px) {
    transform: ${(props) => `scale(${props.scale1000})`};
  }
`;
const Svg = styled.svg`
  background: transparent;
  transition: transform 0.15s ease-in-out;
  transform: scale(1);
	
  :hover {
    cursor: ${p=>p.disabled ? "not-allowed" : "pointer"};
  }
  :focus {
    outline: none;
		animation: hueShift infinite 8s;
  }
  :active {
    outline: none;
    transform: scale(0.9);
  }
`;
const Label = styled.label`
  margin: 0;
  position: absolute;
  pointer-events: none;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
  color: ${(props) =>
    props.isBeingClicked || props.isFocused
      ? "var(--focus)"
      : props.isHovered
      ? "var(--hover)"
      : props.isPowered
      ? "var(--power3)"
      : props.dark
      ? "var(--text-dark)"
      : "var(--text-light)"};
  transform: ${(props) => (props.isBeingClicked ? "scale(0.9)" : "scale(1)")};
  transition: transform 0.15s ease-in-out;
	animation: ${p => p.isFocused && "hueShift infinite 8s"};
`;
