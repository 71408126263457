import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCallToDisplay } from "../../redux/actions";
import styled from "styled-components";

import ShiftingBar from "./ShiftingBar";
import NavButton from "./NavButton";

import navInfo from "../../helpers/navInfo";
import transitionTime from "../../constants/transitionTime";

const DesktopNav = () => {
  const settings = useSelector((state) => state.settings);
  const [buttonsAreDisabled, setButtonsAreDisabled] = useState(false);
  const dispatch = useDispatch();
  return (
    <Container>
      {settings.navOptions.map((optionName, index) => {
        return (
          <NavButton
            key={index}
            optionName={optionName}
            isDisabled={buttonsAreDisabled}
            handleClick={() => {
              if (settings.callToDisplay !== optionName) {
                setButtonsAreDisabled(true);
                setTimeout(() => {
                  setButtonsAreDisabled(false);
                }, transitionTime);
                dispatch(setCallToDisplay(optionName));
								// console.log(optionName)
								const navOption = navInfo.find(option=>option.navName === optionName);
								if (!navOption) return console.log(`ERROR handling optionName: `, optionName)
								window.history.pushState({}, "", navOption.path)
              }
            }}
          />
        );
      })}
      {settings.windowWidth>500 && <ShiftingBar/>}
    </Container>
  );
};
export default DesktopNav;

const Container = styled.nav`
  height: 100%;
  display: none;
  align-items: center;
  flex-wrap: nowrap;
  @media screen and (min-width: 800px) {
    display: flex;
  }
`;
