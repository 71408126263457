import React from "react";
import IconButton from "../../shared/IconButton";
import { useDispatch } from "react-redux";
import { setCallToDisplay } from "../../../redux/actions";

// Icons imports
import pentrisBlock from "../../../assets/svgs/pentrisBlock";

const PentrisEasterEgg = () => {
  const dispatch = useDispatch();
  return (
    <IconButton
      handleClick={() => {
        dispatch(setCallToDisplay("PENTRIS"));
        window.history.pushState({}, "", "/pentris")
      }}
      icon={pentrisBlock}
      label="pentris?"
    />
  );
};
export default PentrisEasterEgg;
