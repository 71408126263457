import React from "react";
import styled from "styled-components";
import NeonDot from "../../shared/NeonDot";

const ProjectSectionUlRenderer = ({ twoColumnWidth, list }) => {
  return (
    <Ul twoColumnWidth = {twoColumnWidth || 999999}>
      {list.map((content, index) => {
        return (
          <UlElementContainer key={index}>
            <NeonDot />
            <Span>{content}</Span>
          </UlElementContainer>
        );
      })}
    </Ul>
  );
};
export default ProjectSectionUlRenderer;

const Ul = styled.ul`
  width: 100%;
  padding-left: 0;
	@media screen and (min-width: ${p => p.twoColumnWidth}px) {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
`;
const Span = styled.span`
  background: ${(props) =>
    !props.isPowered
      ? "inherit"
      : props.dark
      ? "-webkit-linear-gradient(-45deg, var(--text-dark), var(--power2))"
      : "-webkit-linear-gradient(-45deg, var(--text-light), var(--power2))"};
  -webkit-background-clip: ${(props) => props.isPowered && "text"};
  background-clip: ${(props) => props.isPowered && "text"};
  -webkit-text-fill-color: ${(props) => props.isPowered && "transparent"};
`;

const UlElementContainer = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-left: 0.5rem;
  @media screen and (min-width: 300px) {
    padding-left: 1rem;
  }
  @media screen and (min-width: 500px) {
    padding-left: 1.5rem;
  }
  @media screen and (min-width: 800px) {
    padding-left: 2rem;
  }
`;
