import { useState, useEffect } from "react";

const InPortView = ({ inViewPort, setInViewPort, elementIdInQuestion, altInViewDivisor }) => {
  const [listenersCreated, setListenersCreated] = useState(false);
	const divisor = altInViewDivisor || 8;

  function inViewPortVerifier() {
    const elem = document.getElementById(elementIdInQuestion);
    if (elem) {
      const rect = elem.getClientRects();
      if (rect && rect[0]) {
        const windowHeight =
          window.innerHeight || document.documentElement.clientHeight;
        const windowWidth =
          window.innerWidth || document.documentElement.clientWidth;
        // const inView =
        //   rect[0].top <= windowHeight && rect[0].height - rect[0].top >= 0;
        const inView = rect[0].top + (1 * windowWidth) / divisor <= windowHeight;
        return inView;
      } else {
        // console.log("error getting client rects");
        return false;
      }
    } else {
      // console.log(`error targetting container element: ${elementIdInQuestion}`);
      return false;
    }
  }

  useEffect(() => {
		// console.log("init")
    const isInViewPort = inViewPortVerifier();
    function inViewSetter() {
      const isInViewPort = inViewPortVerifier();
      if (isInViewPort) {
        setInViewPort(true);
        document.removeEventListener("scroll", inViewSetter);
        document.removeEventListener("resize", inViewSetter);
      }
    }
    if (isInViewPort) {
      setInViewPort(true);
    } else {
      setListenersCreated(true);
      document.addEventListener("scroll", inViewSetter);
      document.addEventListener("resize", inViewSetter);
    }
    return () => {
      document.removeEventListener("scroll", inViewSetter);
      document.removeEventListener("resize", inViewSetter);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (inViewPort && listenersCreated) {
      document.removeEventListener("scroll", inViewPortVerifier);
      document.removeEventListener("resize", inViewPortVerifier);
    }
  }, [inViewPort]); // eslint-disable-line react-hooks/exhaustive-deps

  return null
};
export default InPortView;
