// SETTINGS ACTIONS

// toggles dark mode:
export const toggleDarkMode = () => ({
  type: "TOGGLE_DARK_MODE",
});

// toggles the power of the home page:
export const toggleHomePower = () => ({
	type: "TOGGLE_HOME_POWER"
})

// sets the power to a specified state
export const setHomePower = (powerStatus) => ({
	type: "SET_HOME_POWER",
	powerStatus
})

// recalculates the dimensions of the window
export const recalculateWindowDimensions = () => ({
	type: "RECALCULATE_WINDOW_DIMENSIONS"
})

// modifies contents of the main Body
export const setCallToDisplay = (newCallToDisplay) => ({
	type: "SET_CALL_TO_DISPLAY",
	newCallToDisplay
})

// shifts a new nav option into the nav options array
export const shiftAdditionalNavOption = (additionalNavOption)=>({
	type: "SHIFT_ADDITIONAL_NAV_OPTION",
	additionalNavOption
})

// sets button type
export const setButtonType = (newButtonNumber) => ({
	type: "SET_BUTTON_TYPE",
	newButtonNumber
})