import React from "react";
import styled from "styled-components";

import Header from "./Header";
import About from "./About";
import PlayButton from "./PlayButton";

const AMazeMe = () => {
  return (
    <Container id="a-maze-me">
      <Header/>
			<About/>
			<PlayButton/>
    </Container>
  );
};
export default AMazeMe;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
	position: relative;
  padding: 0 10px 25vh 10px;
  @media screen and (min-width: 500px) {
    padding: 0 15px 25vh 10px;
  }
  @media screen and (min-width: 800px) {
    padding: 0 20px 25vh 10px;
  }
`;
