import {useState, useEffect} from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { setCallToDisplay } from "../../redux/actions";

import NavButton from "./NavButton";
import navInfo from "../../helpers/navInfo";
import transitionTime from "../../constants/transitionTime";

const ExpandedMobileOptions = ({expandedState, setExpandedState}) => {
    const [yHeight, setYHeight] = useState(0);
    const settings = useSelector((state) => state.settings);
    const dispatch = useDispatch();

    useEffect(()=>{
        if (expandedState === "opening") {
            setTimeout(()=>{
                setYHeight(2)
            },1);
        } else if (expandedState === "closing") {
            setYHeight(0);
        }
    },[expandedState])

    return (
        <OptionsContainer
        $yHeight={yHeight} 
        $transitionTime={transitionTime}
        $powered={settings.homePowerIsOn}
        id="mobile-nav-container"
        >

    {settings.navOptions.map((optionName, index) => {
      return (
        <NavButton
          key={index}
          optionName={optionName}
          isDisabled={expandedState !== true}
          hideGlowing={true}
          handleClick={() => {
            if (settings.callToDisplay !== optionName) {
                setExpandedState("closing");
                setTimeout(() => {
                    setExpandedState(false);
                }, transitionTime);
                dispatch(setCallToDisplay(optionName));
                const navOption = navInfo.find(option => option.navName === optionName);
                if (!navOption) return console.log(`ERROR handling optionName: `, optionName)
                window.history.pushState({}, "", navOption.path)
            }
          }}
        />
      );
    })}

  </OptionsContainer>)
}

export default ExpandedMobileOptions

const OptionsContainer = styled.div`
    display: grid;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: var(--bg1-dark);
    z-index: 9003;
    overflow: hidden;
    transform: scaleY(${({$yHeight})=>$yHeight}) scaleX(2);
    transition: transform ${({$transitionTime})=>$transitionTime}ms ease-in-out;
    transform-origin: top right;
    border: calc(0.5 * var(--border-size)) ${({$powered}) => $powered ? "var(--power2)":"white"} solid;
    border-color: white;
    right calc(-2 * var(--border-size));
`
  