import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import scrollUp from "../../helpers/scrollUp";

const Logo = () => {
  const settings = useSelector((state) => state.settings);

  return (
    <LogoWrapper
      $isPowered={settings.homePowerIsOn}
      onClick={() => {
        scrollUp("smooth")
      }}
    >
      <ResizingContainer
        className="resizing"
      >
        <TopHalf>
          <TopText
            className="unselectable"
            isPowered={settings.homePowerIsOn}
          >
            ATD
          </TopText>
        </TopHalf>
        <BottomHalf
          className="bottom"
          isPowered={settings.homePowerIsOn}
        >
          <BottomText className="unselectable">ATD</BottomText>
        </BottomHalf>
      </ResizingContainer>
    </LogoWrapper>
  );
};
export default Logo;

const LogoWrapper = styled.button`
  position: relative;
  height: 40px;
  width: 40px;
  padding: 0;
  border: none;
  font-family: Georgia, serif;
  transform: rotate(45deg);
  cursor: pointer;
  backface-visibility: hidden;
  & * {
    backface-visibility: hidden;
  }

  color: ${({ $isPowered }) => $isPowered ? "var(--power3)" : "black"};
  & * {
    color: ${({ $isPowered }) => $isPowered ? "var(--power3)" : "black"};
  }
  backface-visibility: hidden;
  animation: ${({ $isPowered }) => $isPowered && "poweredGlow 2s ease-in-out infinite"};
  & .resizing {
    outline: 2px solid ${({ $isPowered }) => $isPowered ? "var(--power3)" : "black"}};
  }
  &:hover {
    animation: ${({ $isPowered }) => $isPowered && "homeLogoGlow 2s ease-in-out infinite"};
    color: var(--hover);
  }
  &:hover * {
    color: var(--hover);
    outline-color: var(--hover);
  }
  
  &:focus {
    outline-color: var(--focus);
    color: var(--focus);
    animation: ${({ $isPowered }) => $isPowered && "homeLogoGlowFocus 8s ease-in-out infinite,"} hueShift infinite 8s};
  }
  &:focus * {
    color: var(--focus);
    outline-color: var(--focus);
  }
  &:active {
    outline: none;
  }
  &:active .resizing {
    top: 1px;
    left: 1px;
  }
  scale: .5;
  top: 1px;
  left: 0px;
  @media screen and (min-width: 500px) {
    scale: 1;
    left: 20px;
    top: 0;
  }
  outline: 1px white solid;
  outline-offset: 1px;

  & .bottom {
    background-color: ${({ $isPowered }) => $isPowered ? "var(--power3)" : "black"}
  }
  &:focus .bottom{
    background-color: var(--focus);
  }
  &:hover .bottom{
    background-color: var(--hover);
  }
`;

const ResizingContainer = styled.div`
  overflow: hidden;
  position: relative;
  border-width: 2px;
  border-style: solid;
  height: 100%;
  outline-offset: -2px;
  background-color: white;
`;

const TopHalf = styled.div`
  transform: rotate(-45deg);
  position: absolute;
  height: 56.58px;
  width: 56.58px;
  top: -30px;
  left: -30px;
  overflow: hidden;
  background-color: white;
`;

const TopText = styled.div`
  width: 100%;
  color: ${(props) =>
    props.isPowered
      ? "var(--power3)"
      : "black"};
  position: absolute;
  bottom: -13px;
  left: 1px;
  font-size: 20px;
  text-align: center;

`;
const BottomHalf = styled.div`
  transform: rotate(-45deg);
  /* transform-origin: bottom right; */
  position: absolute;
  height: 56.58px;
  width: 56.58px;
  bottom: -30px;
  right: -30px;
  overflow: hidden;
  :hover {
    cursor: pointer;
  }
`;
const BottomText = styled.div`
  width: 100%;
  color: white !important;
  position: absolute;
  top: -14px;
  left: 1px;
  font-size: 20px;
  text-align: center;
  :hover {
    cursor: pointer;
  }
`;
