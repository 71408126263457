import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import Logo from "./Logo";
import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";

const MAX_SIZE = 80;

const NavBar = () => {
  const settings = useSelector((state) => state.settings);
  return (
    <>
      {!settings.isSafari && <Fader id="fade-bar" style={{background: settings.dark ? "-webkit-linear-gradient(var(--bg1-dark), transparent)":"-webkit-linear-gradient(var(--bg1-light), transparent)"}}/>}
      <Container style={{backgroundColor: settings.dark ? "var(--bg1-dark)":"var(--bg1-light)"}} id="nav-bar">
        <Logo />
        <DesktopNav />
        <MobileNav />
      </Container>
      <Spacer />
    </>
  );
};
export default NavBar;

const Container = styled.header`
  width: 100vw;
  height: ${MAX_SIZE/2}px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  z-index: 100;
  position: fixed;
  top: 0;
  left: -var(--border-size);

  border: var(--border-size) solid white;
  border-bottom: none;
  color: white;
  @media screen and (min-width: 500px) {
    height: ${MAX_SIZE}px;
  }
`;

const Spacer = styled.div`
  height: ${MAX_SIZE}px;
  @media screen and (min-width: 500px) {
    height: ${MAX_SIZE*2}px;
  }
  border: var(--border-size) solid white;
  border-bottom: none;
  border-top: none;
`;
const Fader = styled.div`
  width: 100%;
  position: fixed;
  height: ${MAX_SIZE/2}px;
  z-index: 100;
  border: var(--border-size) solid white;
  border-top:none;
  border-bottom:none;
  margin-top: ${MAX_SIZE/2}px;
  @media screen and (min-width: 500px) {
    height: ${MAX_SIZE}px;
    margin-top: ${MAX_SIZE}px;
  }
`;
