import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const Question = ({ content, number, inViewPort, slideInsComplete }) => {
  const settings = useSelector((state) => state.settings);
  const [xTranslation, setXTranslation] = useState("translateX(100vw)");

  useEffect(() => {
    if (!inViewPort) return;
    function setXTranslationToZero() {
      setXTranslation("translateX(0)");
    }
    let timer = setTimeout(setXTranslationToZero, number * 500);
    return () => {
      clearTimeout(timer);
    };
  }, [inViewPort]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <P
      className="smallScalingText"
      number={number}
      slideInsComplete={slideInsComplete}
      xTranslation={xTranslation}
      isPowered={settings.homePowerIsOn}
      dark={settings.dark}
    >
      {content}
    </P>
  );
};
export default Question;

const P = styled.p`
  /* font-size: calc(11px + 2vmin); */
  transform: ${(props) => props.xTranslation};
  transition: transform 0.5s ease-in;
  -webkit-text-fill-color: ${(props) =>
    props.slideInsComplete && props.isPowered && "transparent"};
  background: ${(props) =>
    props.slideInsComplete
      ? !props.isPowered
        ? props.dark
          ? "var(--text-dark)"
          : "var(--text-light)"
        : props.dark
        ? "-webkit-linear-gradient(-45deg, var(--text-dark), var(--power2))"
        : "-webkit-linear-gradient(-45deg, var(--text-light), var(--power2))"
      : "inherit"};
  -webkit-background-clip: ${(props) => props.slideInsComplete && "text"};
  background-clip: ${(props) => props.slideInsComplete && "text"};
  margin: 0;
  padding: 0.1rem 0;
  padding-top: ${(props) => props.number === 0 && "0"};
  @media screen and (min-width: 300px) {
    padding: 0.2rem 0;
    padding-top: ${(props) => props.number === 0 && "0"};
  }
  @media screen and (min-width: 500px) {
    padding: 0.3rem 0;
    padding-top: ${(props) => props.number === 0 && "0"};
  }
  @media screen and (min-width: 800px) {
    padding: 0.5rem 0;
    padding-top: ${(props) => props.number === 0 && "0"};
  }
  @media screen and (min-width: 1200px) {
    padding: 0.7rem 0;
    padding-top: ${(props) => props.number === 0 && "0"};
  }
`;
