import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import ALink from "../../../shared/ALink";

const Entry = ({ info, isLast }) => {
  const settings = useSelector((state) => state.settings);
  return (
    <Container
      dark={settings.dark}
      isPowered={settings.homePowerIsOn}
      // className="scalingBottomMargin scalingPadding"
      className="scalingBottomMargin"
      isLast={isLast}
    >
      <TitleContainer className="centered halfScalingBottomMargin fullWidth">
        <Company
          className="noTopMargin noBottomMargin"
          dark={settings.dark}
          isPowered={settings.homePowerIsOn}
        >
          {info.company}
        </Company>
        {settings.windowWidth >= 600 && (
          <Spacer dark={settings.dark} isPowered={settings.homePowerIsOn}>
            :
          </Spacer>
        )}
        <Job
          className="noTopMargin noBottomMargin"
          dark={settings.dark}
          isPowered={settings.homePowerIsOn}
        >
          <strong>{info.job}</strong>
        </Job>
      </TitleContainer>
      <P
        className="noTopMargin halfScalingBottomMargin"
        dark={settings.dark}
        isPowered={settings.homePowerIsOn}
      >
        {info.time}
      </P>
      <P
        className="noTopMargin halfScalingBottomMargin fullWidth"
        dark={settings.dark}
        isPowered={settings.homePowerIsOn}
      >
        {info.description1}
      </P>
      <P
        className={`noTopMargin ${
          info.link
            ? "halfScalingBottomMargin"
            : isLast
            ? "noBottomMargin"
            : "scalingBottomMargin"
        } fullWidth`}
        dark={settings.dark}
        isPowered={settings.homePowerIsOn}
      >
        {info.description2}
      </P>
      {info.link && (
        <ALink
          href={info.link.href}
          newTab={true}
          label={info.link.label}
          content={settings.windowWidth > 350 ? info.link.content : info.link.smallContent}
          paddingBottom="0px"
          classNames="scalingBottomMargin"
          removeNoMargin={1}
        />
      )}
    </Container>
  );
};
export default Entry;

const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
	flex-wrap: wrap;
  border-bottom: ${(p) => !p.isLast && "1px dashed"};
  border-color: ${(p) =>
    p.isPowered ? "var(--text-dark)" : "var(--bg3-dark)"};
`;
const Spacer = styled.div`
  padding: 0 5px;
  color: ${(p) => (p.isPowered ? "var(--power1)" : "var(--power2)")};
`;
const TitleContainer = styled.div`
  flex-wrap: nowrap;
  flex-direction: column;
  @media screen and (min-width: 600px) {
    flex-direction: row;
  }
`;
const Company = styled.h4`
  white-space: nowrap;
  color: ${(p) => (p.isPowered ? "var(--power1)" : "var(--power2)")};
`;
const Job = styled.p`
  white-space: nowrap;
  color: ${(p) => (p.isPowered ? "var(--power1)" : "var(--power2)")};
`;
const P = styled.p`
  background: ${(props) =>
    !props.isPowered
      ? "inherit"
      : props.dark
      ? "-webkit-radial-gradient(var(--power2), var(--text-dark))"
      : "-webkit-radial-gradient(var(--power2), var(--text-light))"};
  -webkit-background-clip: ${(props) => props.isPowered && "text"};
  background-clip: ${(props) => props.isPowered && "text"};
  -webkit-text-fill-color: ${(props) => props.isPowered && "transparent"};
`;
