import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Header from "./Header";
import Banner from "./Banner";
import Teasers from "./Teasers";
import ViewProjects from "./ViewProjects";
import FindMe from "./FindMe";
import OtherInterests from "./OtherInterests";
import ChangeButtonShapes from "./ChangeButtonShapes";
import EasterEggFooter from "./EasterEggFooter";

const About = () => {
  const settings = useSelector((state) => state.settings);
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  return (
    <>
      <Container dark={settings.dark} id="about-me">
        <Header />
        <Banner hasMounted={hasMounted} />
        <Teasers />
        <ViewProjects />
        <FindMe />
        <OtherInterests />
        <ChangeButtonShapes />
      </Container>
			<EasterEggFooter/>
    </>
  );
};
export default About;

const Container = styled.div`
  /* position: relative; */
  width: 100%;
  background-color: ${(props) =>
    props.dark ? `var(--bg1-dark)` : `var(--bg1-light)`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;
  @media screen and (min-width: 500px) {
    text-align: center;
    padding: 0 15px;
  }
  @media screen and (min-width: 800px) {
    padding: 0 20px;
  }
`;