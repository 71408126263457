import About from "../Components/About";
import Resume from "../Components/Resume";
import Portfolio from "../Components/Portfolio";
import ChapterOne from "../Components/ChapterOne";
import AMazeMe from "../Components/SideProjectEasterEggs/AMazeMe";
import Pentris from "../Components/SideProjectEasterEggs/Pentris";
import BattleShip from "../Components/SideProjectEasterEggs/BattleShip";
import LearningMathGame from "../Components/SideProjectEasterEggs/LearningMathGame";

import DeezBotBoisInfo from "../Components/Portfolio/projects/deezBotBois/DeezBotBoisInfo";
import SudokuSolverInfo from "../Components/Portfolio/projects/sudokuSolver/SudokuSolverInfo";
import SyncroSonicInfo from "../Components/Portfolio/projects/syncroSonic/SyncroSonicInfo";

const navInfo = [
  {
    baseOption: true,
    fallback: true,
    navName: "ABOUT ME",
    path: "/",
    altPath: "/about",
    component: () => <About/>,
  },
	
  // bonus routes
  { navName: "PENTRIS", path: "/pentris", component: ()=><Pentris/> },
  { navName: "BS", path: "/battle-ship", component: ()=><BattleShip/> },
	{ navName: "MATH-GAME", path: "/math-game", component: ()=><LearningMathGame/> },
  { navName: "CH 1", path: "/chapter01", component: ()=><ChapterOne/> },
	{ navName: "A-MAZE-ME", path: "/a-maze-me", component: ()=><AMazeMe/> },

  // portfolio routes
  { navName: "SYNCRO", path: "/portfolio/syncro", component: ()=><SyncroSonicInfo/> },
  { navName: "SUDOKU", path: "/portfolio/sudoku", component: ()=><SudokuSolverInfo/> },
  { navName: "BOT BOIS", path: "/portfolio/bot-bois", component: ()=><DeezBotBoisInfo/> },
  {
    baseOption: true,
    navName: "PORTFOLIO",
    path: "/portfolio",
    component: () => <Portfolio/>,
  },

  { baseOption: true, navName: "RESUME", path: "/resume", component: ()=><Resume/> },
];

export const portfolioSubNav = [
	{path: "/portfolio/syncro-sonic", navName: "PORTFOLIO"},
	{path: "/portfolio/botbois", navName: "PORTFOLIO"},
	{path: "/portfolio/sudoku", navName: "PORTFOLIO"},
]

export default navInfo;
