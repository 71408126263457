import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const Bot = ({ size, ally, you, enemy }) => {
  const settings = useSelector((state) => state.settings);

  // └[∵┌]└[ ∵ ]┘[┐∵]┘
  // 8, 32, 40
  return (
    <Container dark={settings.dark} size={size}>
      <IndicationPoint ally={ally} you={you} enemy={enemy} />
      <Indication dark={settings.dark} ally={ally} you={you} enemy={enemy}>
        {you ? "YOU" : ally ? "ALLY" : "ENEMY"}
      </Indication>
      <Head className="centepurple" ally={ally} you={you} enemy={enemy}>
        <Eye left={1} ally={ally} you={you} enemy={enemy} />
        <Eye right={1} ally={ally} you={you} enemy={enemy} />
      </Head>
      <Neck ally={ally} you={you} enemy={enemy} />
      <Body ally={ally} you={you} enemy={enemy} />
      <Wheel left={1} ally={ally} you={you} enemy={enemy} />
      <Wheel right={1} ally={ally} you={you} enemy={enemy} />
    </Container>
  );
};
export default Bot;

const Container = styled.div`
  width: 100%;
  position: relative;
  height: ${(p) => p.size}px;
`;

const Head = styled.div`
  position: absolute;
  height: 20%;
  width: 40%;
  background: ${(p) =>
    p.you ? "deepskyblue" : p.ally ? "lime" : p.enemy ? "purple" : "silver"};
  left: 30%;
  top: 5%;
  border-radius: 5%;
  border: 1px
    ${(p) =>
      p.you ? "violet" : p.ally ? "silver" : p.enemy ? "orange" : "silver"}
    solid;
  @media screen and (min-width: 800px) {
    border-width: 2px;
  }
`;
const Eye = styled.div`
  position: absolute;
  width: 70%;
  top: 20%;
  height: 40%;
  width: 10%;
  border-radius: 50%;
  left: ${(p) => p.left && "20%"};
  right: ${(p) => p.right && "20%"};
  background: ${(p) =>
    p.you ? "white" : p.ally ? "blue" : p.enemy ? "red" : "silver"};
`;
const Neck = styled.div`
  position: absolute;
  width: 10%;
  left: 45%;
  top: 25%;
  height: 15%;
  background: ${(p) =>
    p.you ? "violet" : p.ally ? "silver" : p.enemy ? "orange" : "silver"};
`;
const Body = styled.div`
  position: absolute;
  width: 50%;
  left: 25%;
  top: 40%;
  height: 35%;
  border-radius: 25% 25% 0 0;
  background: ${(p) =>
    p.you ? "deepskyblue" : p.ally ? "lime" : p.enemy ? "purple" : "silver"};
  border: 1px
    ${(p) =>
      p.you ? "violet" : p.ally ? "silver" : p.enemy ? "orange" : "silver"}
    solid;
  @media screen and (min-width: 800px) {
    border-width: 2px;
  }
`;
const Wheel = styled.div`
  position: absolute;
  width: 10%;
  left: ${(p) => p.left && "30%"};
  right: ${(p) => p.right && "30%"};
  top: 75%;
  height: 15%;
  background: ${(p) =>
    p.you ? "violet" : p.ally ? "silver" : p.enemy ? "orange" : "silver"};
  border-radius: 0 0 50% 50%;
`;
const IndicationPoint = styled.div`
  position: absolute;
  margin: 0 auto;
  bottom: 120%;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  height: 10px;
  width: 10px;

  justify-self: center;
  align-self: center;
  background-color: ${(props) =>
    props.dark ? `var(--bg2-dark)` : `var(--bg2-light)`};
  background: ${(p) =>
    p.you ? "deepskyblue" : p.ally ? "lime" : p.enemy ? "red" : "silver"};
  @media screen and (min-width: 300px) {
    height: 15px;
    width: 15px;
  }
  @media screen and (min-width: 500px) {
    height: 25px;
    width: 25px;
  }
  @media screen and (min-width: 800px) {
    height: 40px;
    width: 40px;
  }
  @media screen and (min-width: 1000px) {
    height: 50px;
    width: 50px;
  }
`;
const Indication = styled.p`
  position: absolute;
  margin: 0 auto;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  border-radius: 5px;
  justify-self: center;
  align-self: center;
  background-color: ${(props) =>
    props.dark ? `var(--bg3-dark)` : `var(--bg3-light)`};
  color: ${(p) =>
    p.you ? "deepskyblue" : p.ally ? "lime" : p.enemy ? "red" : "silver"};
  /* border: 2px
    ${(p) =>
    p.you ? "violet" : p.ally ? "silver" : p.enemy ? "orange" : "silver"}
    solid; */
  -webkit-text-fill-color: ${(p) =>
    p.you ? "deepskyblue" : p.ally ? "lime" : p.enemy ? "red" : "silver"};
  border-width: 2px;
  border-style: solid;
  @media screen and (min-width: 800px) {
    border-width: 4px;
    padding: 10px;
    border-radius: 10px;
  }
`;
