import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import NeonText from "../../shared/NeonText";
import ImageLinksAndText from "./ImageLinksAndText";

const Demo = ({ demoInfo, viewProject, setViewProject }) => {
  const settings = useSelector((state) => state.settings);
  return (
    <Container
      dark={settings.dark}
      id={demoInfo.header}
			aria-labelledby={`${demoInfo.header}-section`}
    >
      <NeonText
        content={demoInfo.header}
        classNames="noTopMargin largeScalingText"
      />
      <ImageLinksAndText
        demoInfo={demoInfo}
        viewProject={viewProject}
        setViewProject={setViewProject}
      />
    </Container>
  );
};
export default Demo;

const Container = styled.section`
  width: 100%;
  background-color: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 6rem;
`;
