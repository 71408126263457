import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { setCallToDisplay } from "../../redux/actions";
import AngledButton from "../shared/AngledButton";
import NeonText from "../shared/NeonText";

const ViewProjects = () => {
  const settings = useSelector((state) => state.settings);
  const dispatch = useDispatch();
  return (
    <Container dark={settings.dark}>
      <NeonText
        content="Projects"
        classNames="largeScalingText scalingBottomMargin"
      />
      <P
        dark={settings.dark}
        isPowered={settings.homePowerIsOn}
        className="noTopMargin scalingBottomMargin"
      >
        Want to see some things I've worked on?
      </P>
      <ButtonPlacement>
        <AngledButton
          label="Go to my portfolio button"
          innerText="See my portfolio!"
          title="Go to my portfolio button"
          transformOrigin="center"
          handleClick={() => {
            dispatch(setCallToDisplay("PORTFOLIO"));
						window.history.pushState({}, "", "/portfolio")
          }}
        />
      </ButtonPlacement>
    </Container>
  );
};
export default ViewProjects;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  margin-bottom: 1rem;
  @media screen and (min-width: 500px) {
    margin-bottom: 1.5rem;
  }
  @media screen and (min-width: 800px) {
    margin-bottom: 2rem;
  }
`;
const P = styled.p`
  background: ${(props) =>
    !props.isPowered
      ? props.dark
        ? "var(--text-dark)"
        : "var(--text-light)"
      : props.dark
      ? "-webkit-linear-gradient(-45deg, var(--text-dark), var(--power2))"
      : "-webkit-linear-gradient(-45deg, var(--text-light), var(--power2))"};
	background-clip: text;
  -webkit-background-clip: text;
  max-width: 60ch;
  -webkit-text-fill-color: transparent;
  @media screen and (min-width: 500px) {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
`;
const ButtonPlacement = styled.div`
  width: fit-content;
  margin: auto;
`;
