function scrollUp(altScrollBehavior, altTop) {
  window.scroll({
    top: typeof altTop === "number" ? altTop : 0,
    behavior: altScrollBehavior === "smooth" ? "smooth" : "auto" 
  });
}
export default scrollUp;

// alternative means of scrolling below, but they break the functionality

// document.body.scrollTop = 0;
// document.documentElement.scrollTop = 0;