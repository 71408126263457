import {useState, useEffect} from "react";

const useHideIfIdPresent = (targetId, pollingInterval = 100) => {
    const [hide, setHide] = useState(false);
    useEffect(() => {
        const timer = setInterval(() => {
            const foundMobileNav = document.getElementById(targetId);
            if (!foundMobileNav) {
                setHide(false) 
            } else { 
                setHide(true)
            }
        }, pollingInterval)
        return ()=>{
          clearInterval(timer)
        }
      }, [])
    return hide
}

export default useHideIfIdPresent