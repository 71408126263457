import React from "react";
import styled from "styled-components";
const Header = () => (
  <Title className="centered scalingBottomMargin">
    BATTLESHIP
  </Title>
);

export default Header;

const Title = styled.h2`
	text-shadow: -1px -1px 0 cyan, 1px -1px 0 cyan, -1px 1px 0 cyan, 1px 1px 0 cyan;
	letter-spacing: .1em;
	color: lightgray;
`