// React imports
import React from "react";

// Shared imports
import NeonText from "../../../shared/NeonText";

// Local imports
import ProjectContainer from "../ProjectContainer";
import ReturnToOverviewTopIcon from "../ReturnToOverviewTopIcon";
import CollapsableContentNavigation from "../CollapsableContentNavigation";
import ReturnToOverviewButton from "../ReturnToOverviewButton";
import Motivation from "./Motivation";
import Approach from "./Approach";
import Difficulties from "./Difficulties";
import Resolution from "./Resolution";

const SudokuSolverInfo = () => {
  return (
    <ProjectContainer id="sudoku-solver-project">
      <ReturnToOverviewTopIcon/>
      <CollapsableContentNavigation contentList={["Motivation", "Approach", "Difficulties", "Resolution"]} />
      <NeonText
        content="Sudoku Solver"
        classNames="largerScalingText doubleScalingBottomMargin"
      />
      <Motivation />
      <Approach />
      <Difficulties />
      <Resolution />
      <ReturnToOverviewButton/>
    </ProjectContainer>
  );
};
export default SudokuSolverInfo;
