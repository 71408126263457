import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import NeonText from "../shared/NeonText";

const SectionContainer = ({ children, title }) => {
  const settings = useSelector((state) => state.settings);
  return (
    <Container
      dark={settings.dark}
      isPowered={settings.homePowerIsOn}
      id={`${title.toLowerCase()}-container`}
      className="scalingBottomPadding scalingTopPadding"
    >
      <NeonText
        content={title}
        classNames="largeScalingText scalingTopAndBottomMargin"
      />
      {children}
    </Container>
  );
};
export default SectionContainer;

const Container = styled.section`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 2px dashed;
  border-color: ${(p) => (p.isPowered ? "var(--power2)" : "var(--text-dark)")};
  max-width: 1000px;
  @media screen and (min-width: 500px) {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
`;
