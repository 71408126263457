// React imports
import React from "react";

// Shared imports
import NeonText from "../../../shared/NeonText";
// import Emoji from "../../../shared/Emoji";

// Local imports
import ProjectContainer from "../ProjectContainer";
import ReturnToOverviewTopIcon from "../ReturnToOverviewTopIcon";
import CollapsableContentNavigation from "../CollapsableContentNavigation";
import ReturnToOverviewButton from "../ReturnToOverviewButton";
import Introduction from "./Introduction";
import Approach from "./Approach";
import Difficulties from "./Difficulties";
import Resolution from "./Resolution";

const SyncroSonicInfo = () => {
  
  return (
    <ProjectContainer id="syncro-sonic-project">
      <ReturnToOverviewTopIcon />
      <CollapsableContentNavigation
        contentList={["Introduction", "Approach", "Difficulties", "Resolution"]}
      />
      {/* 
			Possible construction emojis to use: 🚧 🔨 👷
			<p style={{ textAlign: "center" }}>
        <Emoji>🚧</Emoji> Please note: this section is currently under
        construction <Emoji>🚧</Emoji>
      </p> */}
      <NeonText
        content="Syncro Sonic"
        classNames="largerScalingText doubleScalingBottomMargin"
      />
      <Introduction />
      <Approach />
      <Difficulties />
      <Resolution />
      <ReturnToOverviewButton />
    </ProjectContainer>
  );
};
export default SyncroSonicInfo;
