import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const Entry = ({ info, isLast }) => {
  const settings = useSelector((state) => state.settings);
  return (
    <Container
      dark={settings.dark}
      isPowered={settings.homePowerIsOn}
      className="scalingBottomMargin"
      isLast={isLast}
    >
      <Program
        className="noTopMargin halfScalingBottomMargin"
        dark={settings.dark}
        isPowered={settings.homePowerIsOn}
      >
        {info.program}
      </Program>
      <Name
        className="noTopMargin halfScalingBottomMargin"
        dark={settings.dark}
        isPowered={settings.homePowerIsOn}
      >
        {info.name}
      </Name>
			<P
        className="noTopMargin halfScalingBottomMargin"
        dark={settings.dark}
        isPowered={settings.homePowerIsOn}
      >
        {info.time}
      </P>
      <P
        className="noTopMargin halfScalingBottomMargin fullWidth"
        dark={settings.dark}
        isPowered={settings.homePowerIsOn}
      >
        {info.description1}
      </P>
      <P
        className={`noTopMargin ${
          info.link
            ? "halfScalingBottomMargin"
            : isLast
            ? "noBottomMargin"
            : "scalingBottomMargin"
        } fullWidth`}
        dark={settings.dark}
        isPowered={settings.homePowerIsOn}
      >
        {info.description2}
      </P>
    </Container>
  );
};
export default Entry;

const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: ${(p) => !p.isLast && "1px dashed"};
  border-color: ${(p) =>
    p.isPowered ? "var(--text-dark)" : "var(--bg3-dark)"};
`;

const Program = styled.h4`
  white-space: nowrap;
  color: ${(p) => (p.isPowered ? "var(--power1)" : "var(--power2)")};
`;
const Name = styled.p`
  background: ${(props) =>
    !props.isPowered
      ? "inherit"
      : props.dark
      ? "-webkit-radial-gradient(var(--power2), var(--text-dark))"
      : "-webkit-radial-gradient(var(--power2), var(--text-light))"};
  -webkit-background-clip: ${(props) => props.isPowered && "text"};
  background-clip: ${(props) => props.isPowered && "text"};
  -webkit-text-fill-color: ${(props) => props.isPowered && "transparent"};
`;
const P = styled.p`
  background: ${(props) =>
    !props.isPowered
      ? "inherit"
      : props.dark
      ? "-webkit-radial-gradient(var(--power2), var(--text-dark))"
      : "-webkit-radial-gradient(var(--power2), var(--text-light))"};
  -webkit-background-clip: ${(props) => props.isPowered && "text"};
  background-clip: ${(props) => props.isPowered && "text"};
  -webkit-text-fill-color: ${(props) => props.isPowered && "transparent"};
`;
