import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import AndrewEasterEgg from "./AndrewEasterEgg";

const messages = [
  "Hello World!",
  "Welcome to my portfolio!",
  "How are you?",
  "Hope you're well ❤️",
	"Care to dance?",
  "└[ ∵ ]┘",
  "└[∵┌]",
  "└[ ∵ ]┘",
  "[┐∵]┘",
  "└[ ∵ ]┘",
  "└[∵┌]",
  "└[ ∵ ]┘",
  "[┐∵]┘",
  "└[ ∵ ]┘",
  "└[∵┌]",
  "└[ ∵ ]┘",
  "[┐∵]┘",
  "└[ ∵ ]┘",
  "└[∵┌]",
  "└[ ∵ ]┘",
  "[┐∵]┘",
  "└[ ∵ ]┘",
  "└[∵┌]",
  "└[ ∵ ]┘",
  "You're still here?",
  "Glad to have you!",
  "Have any questions?",
  "I may have insight.",
  "Consider reaching out.",
  "It's okay to need help.",
  "I can be there for you.",
  "Contact me",
];

const INTERVAL_DELAY = 75;
const DELAYS_BETWEEN_TEXT_CHANGE = 4;
const LONG_HOLD = 25;
const SHORT_HOLD = 10;

const Header = () => {
  const [welcomeMessage, setWelcomeMessage] = useState(["ㅤ",]);
  const [andrewVisible, setAndrewVisible] = useState(false);
  const [turnOffCaret, setTurnOffCaret] = useState(false);
  const settings = useSelector((state) => state.settings);

  useEffect(() => {
    let step = 0;
    let timeout;
    const interval = setInterval(() => {
      step++;
      if (step < DELAYS_BETWEEN_TEXT_CHANGE) return;
      let relevantStep = step - DELAYS_BETWEEN_TEXT_CHANGE;
      for (
        let messageNumber = 0;
        messageNumber < messages.length;
        messageNumber++
      ) {
        const message = messages[messageNumber];
        if (relevantStep < message.length) {
          setWelcomeMessage((currentWelcome) => {
            const newWelcomeMessage = [...currentWelcome];
            newWelcomeMessage.push(message[relevantStep]);
            return newWelcomeMessage;
          });
          return;
        }
        relevantStep -= message.length;
        if (relevantStep < 2 * DELAYS_BETWEEN_TEXT_CHANGE) {
          return;
        }
        setAndrewVisible(true);
        relevantStep -= 2 * DELAYS_BETWEEN_TEXT_CHANGE;
        if (messageNumber === 0) {
          if (relevantStep < LONG_HOLD) {
            return;
          }
          relevantStep -= LONG_HOLD;
        } else {
          if (relevantStep < SHORT_HOLD) {
            return;
          }
          relevantStep -= SHORT_HOLD;
        }
        if (relevantStep < message.length) {
          if (messageNumber === messages.length - 1) {
            timeout = setTimeout(() => {
              setTurnOffCaret(true);
            }, INTERVAL_DELAY * DELAYS_BETWEEN_TEXT_CHANGE * 2);
            return clearInterval(interval);
          }
          setWelcomeMessage((currentWelcome) => {
            if (currentWelcome.length === 1) return currentWelcome
            const newWelcomeMessage = [...currentWelcome];
            newWelcomeMessage.pop();
            return newWelcomeMessage;
          });
          return;
        }
        relevantStep -= message.length;
        if (relevantStep < DELAYS_BETWEEN_TEXT_CHANGE) {
          return;
        }
        relevantStep -= message.length;
      }
    }, INTERVAL_DELAY);
    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps
  // const welcomeText = settings.windowWidth >= 800 ? "Greetings peruser, welcome to my portfolio!":"Welcome to my portfolio!"
  return (
    <>
      <Typing
        className="scalingTopAndBottomMargin mediumScalingText"
        $turnOffCaret={turnOffCaret}
        dark={settings.dark}
        isPowered={settings.homePowerIsOn}
      >
        {welcomeMessage}
      </Typing>
      <AndrewText
        as="h2"
        dark={settings.dark}
        isPowered={settings.homePowerIsOn}
        $andrewVisible={andrewVisible}
        className="scalingTopAndBottomMargin mediumScalingText"
      >
        I'm <AndrewEasterEgg />, <br /> a full-stack web developer,
				<br /> a student,
				<br /> a teacher.
      </AndrewText>
    </>
  );
};
export default Header;

const TextStyles = styled.h1`
  background: ${(props) =>
    !props.isPowered
      ? props.dark
        ? "var(--text-dark)"
        : "var(--text-light)"
      : props.dark
      ? "-webkit-linear-gradient(75deg, var(--text-dark), var(--power2))"
      : "-webkit-linear-gradient(75deg, var(--text-light), var(--power2))"};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const AndrewText = styled(TextStyles)`
  transition: opacity 500ms ease-in-out;
  opacity: ${({ $andrewVisible }) => ($andrewVisible ? 1 : 0)};
	margin-top: 0;
  @media screen and (min-width: 500px) {
    & {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const Typing = styled(TextStyles)`
  overflow: hidden;
  border-right: 0.15em solid
    ${({ $turnOffCaret }) => ($turnOffCaret ? "transparent" : "var(--power3)")};
  white-space: nowrap;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: 0.05em;
  animation: ${({ $turnOffCaret }) =>
    !$turnOffCaret && "blink-caret .75s step-end infinite"};
`;
