import { useState, useEffect } from 'react';

const useMountAndWelcome = () => {
    const [hasMounted, setHasMounted] = useState(false);
     useEffect(() => {
        if (!hasMounted) {
        console.log('%c Welcome to my portfolio! ', 'margin: auto; padding: 5px; background: black; color: hsl(200, 100%, 50%)');
        setHasMounted(true);
        }
    }, [hasMounted, setHasMounted]);    
    return hasMounted
}
export default useMountAndWelcome