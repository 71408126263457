import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import AldredSpeach from "../shared/writing/AldredSpeach";
import TezSpeach from "../shared/writing/TezSpeach";
import Thought from "../shared/writing/Thought";

const ChapterOne = () => {
  const settings = useSelector((state) => state.settings);

  return (
    <Container dark={settings.dark}>
      <h2 style={{ marginLeft: "auto", marginRight: "auto" }}>Chapter One -</h2>
      <h2
        style={{ marginLeft: "auto", marginRight: "auto" }}
        className="noTopMargin"
      >
        Illusory Boundaries
      </h2>
      <p>
        Clutching onto hope for better days, Aldred's mind raced for a way to
        improve his situation. He peeked over the edge of the enormous limb he
        laid upon, but the approaching menace remained unseen within the
        jungle's thicket.{" "}
        <AldredSpeach>"Are you sure they're coming this way?"</AldredSpeach> the
        young man whispered as a fine mist slickened the dirt that covered his
        bronze skin. He tightened his muddy fingers around the grip of his
        sheathed sword, as he imagined having to slash his friend through the
        efficient hunters.{" "}
        <AldredSpeach>"I can still lose them in the branches-"</AldredSpeach>
      </p>
      <p>
        <TezSpeach>"Shut up boy,"</TezSpeach> Tezerald's gravelly voice struck
        through Aldred's mind.{" "}
        <TezSpeach>
          "Their blood approaches, but they are not making for you. Be still,
          and they will be off on their way."
        </TezSpeach>{" "}
        Aldred glanced down at his guide, locked snug within his orange and blue
        gemmed scabbard. He wanted to apologize for making unnecessary noise,
        but knew that would only exacerbate his mistake.
      </p>
      <p>
        The buzz of life hushed in a wave of fear. Aldred's hair protruded
        through the thinning layer of mud on his arms as the guttural laughter
        approached. The chase of oakrum emerged, snapping through curtains of
        stalks and vines. Their leader, the largest of their chase, strutted
        alone on his high branch as he dragged the oozing remains of a gorilla
        with ease. Below, three oakrum shambled along two branches. They
        stretched the prey's guts out and mauled each other over choice morsels.
        Aldred wanted to look away, but he dared not risk the shaking of even
        one leaf.
      </p>
      <p>
        <Thought>I can still run if they see me,</Thought> Aldred reassured
        himself as the light rain continued to clean his dark skin.{" "}
        <TezSpeach>"Would you relax,"</TezSpeach> Tezerald's voice echoed in
        Aldred's head.{" "}
        <TezSpeach>
          "Your heart is pounding needlessly. They can't smell you."
        </TezSpeach>
      </p>
      <p>
        The massive leader's branch bowed as he distanced himself from the
        tree's central support. With a powerful leap, he sprung up fifteen feet
        and hooked onto a new bough with his six inch long claws. The ape's
        innards spattered wastefully, freckling the brown furs of those below
        with gore. They cackled with glee and tore into caught organs with
        jagged teeth. The alpha bit off a hunk of flesh the size of Aldred's
        head from an arm. Aldred waited, frozen with dread and awe.{" "}
        <Thought>If only survival were so effortless.</Thought>
      </p>
      <p>
        A green snake lashed out at its unwelcome guest, sinking its fangs into
        the swaying oakrum's arm. He dropped the gorilla, much to the delight of
        his gang, and snatched the snake by the head. With a maniacal smile, he
        pulled his arm away, unscathed save some raked fur. The bough shook as
        he lifted himself upside down. Hanging from his thick legs, he pinched
        the snake's jaw open like a vice, and snapped off its fangs. The oakrum
        whooped in amusement at the impotent serpent before whipping it into the
        underbrush; to suffer starvation in needless cruelty.
      </p>
      <p>
        Aldred's body stiffened as the leader's laughter stopped abruptly. The
        oakrum righted himself and snuffed at the air with his up-curled nose.
        Following suit, the others indulged in silence as they scanned the
        jungles with their yellow eyes.{" "}
        <Thought>Have they caught my scent?</Thought> Aldred thought as he
        glanced at the thinning mud on his arms. His chest pounded against his
        greenshelled armor as the alpha sniffed and raised his line of sight
        toward Aldred. <Thought>They can't catch me,</Thought> Aldred tried to
        convince himself as he readied himself to leap into the thickest nearby
        brush that would most hamper their movement.
      </p>
      <p>
        Oblivious and playful, a pair of squirrels glid between them for a
        nearby tree. Zoned into their own game, they chittered and scurried
        after each other, drawing unwanted attention. The enticement of the hunt
        superseded the pleasure of food: the gang cast aside their gizzards and
        propelled themselves toward the unfortunate critters. Using their long
        limbs, they swooped along and across the branches, hunting the
        scrambling trophies.{" "}
        <Thought>Challenge makes for greater sport.</Thought>
      </p>
      <p>
        The oakrum rampaged away from Aldred, leaving a trail of broken
        greenery. Their ruckus was quickly swallowed by The Wilds, and the
        chirping of birds and insects returned. Aldred remained motionless for a
        while longer.{" "}
        <AldredSpeach>"How could be sure they couldn't smell me?"</AldredSpeach>{" "}
        Aldred asked his guardian.
      </p>
      <p>
        <TezSpeach>"Why are you asking such a stupid question?"</TezSpeach>{" "}
        Tezerald hissed.{" "}
        <TezSpeach>
          "Of course I could not know such things, but if you require hand
          holding to calm yourself, then I will oblige you."
        </TezSpeach>
      </p>
      <p>
        Aldred risked a slight nod.{" "}
        <AldredSpeach>"I guess I was wasting energy-"</AldredSpeach>
      </p>
      <p>
        <TezSpeach>
          "This whole trip is a waste of energy. You do not need more meek
          extract."
        </TezSpeach>
      </p>
      <p>
        <AldredSpeach>
          "I wasn't expected to bump into oakrum on the way,"
        </AldredSpeach>{" "}
        Aldred said but Tezerald let him stew in silence.{" "}
        <AldredSpeach>
          "You said the conduit is far away, I just want to be sure I have an
          ample supply."
        </AldredSpeach>
      </p>
      <p>
        <TezSpeach>
          "Go on and be quick about it. The sun should be rising now and we
          agreed you would go at first light."
        </TezSpeach>
      </p>
      <p>
        <Thought>Better to go than say you agree,</Thought> Aldred thought as he
        stood and stretched. Mud coated his broad face and dark shaggy hair. He
        wore a collage of makeshift clothing: braided vines and knotted fibres,
        some of which secured smoothed shells onto his thighs and torso. Aldred
        carried two sets of cured leather riggings that clashing with his
        primitive garb. One, black, cracking and pocked, girdled his hips and
        supported Tezerald inside his black sheath. The other, brown, flexible
        and stained, wrapped across his green armored chest and held up a
        matching leather satchel on his right hip. With a smile, he caressed the
        soft hide,
        <Thought>This is all I have left of them,</Thought>
				{" "}he thought.
      </p>
      <p>
        <TezSpeach>"Quit lingering,"</TezSpeach> Tezerald interrupted his
        reverie.
      </p>
      <p>
        <AldredSpeach>"Sorry Tez,"</AldredSpeach> Aldred said by accident,
        having been caught wasting even more time.
      </p>
      <p>
        <TezSpeach>"I do not want your excuses,"</TezSpeach> Tezerald said,{" "}
        <TezSpeach>"mud up and get moving."</TezSpeach>
      </p>
      <p>
        Aldred reached into the satchel and fished around between various
        stoppered shells. He withdrew a dirty spined one, and shook mud out onto
        his calloused hands. As he smeared the muck back onto his skin, he
        surveyed the path that he would take upward. A familiar route, but those
        that did not look for improvement would never find it. Having finished
        his masking, he continued climbing up the colossal tree in a spiral.
      </p>
      <p>
        A plethora of diverse animals inhabited the interweaved foliage that
        grew denser as Aldred approached the canopy. Aldred spotted a snake's
        tail hanging from the next branch and changed his course.{" "}
        <Thought>Never climb directly upward,</Thought> he recalled one of
        Tezerald's rules and took note of yet another time his instruction had
        safeguarded him.{" "}
        <Thought>
          Of course, if I was encased in furs like the oakrum I wouldn't have to
          worry about snakes,
        </Thought>{" "}
        he thought and daydreamed enviously of their many gifts.
      </p>
      <p>
        Aldred rose into the canopy, revealing a sea of rising and falling
        treetops like green waves frozen in time. Day was breaking in from the
        east and scorched the sky red as a warning of its impending heat. The
        dancing moons, Oath and Riddusk, loomed higher still, waning to halves.
        Riddusk glowed blue, and Oath, the larger of the two, boasted dancing
        orange filaments on an otherwise dark surface. Flocks of parrots and
        birds of paradise littered the air and fed on berries and insects.
        Aldred ignored the colorful display and tracked a nearby purple vine. He
        swung toward it, unfazed by the four hundred foot drop below.
      </p>
      <p>
        A foul stench struck Aldred and forced him to breathe through his mouth.
        He followed the vine as it twisted downward, along two trees, before
        ending at his objective. A white flower with eight rounded petals, twice
        the size of his hands, pointed upward. Its pungent vapors forced tears
        from Aldred, who fumbled through his satchel for a few empty shells. As
        he reached for the flower, a bee buzzed out toward him, but he swiped it
        out of the air with a backhand. Aldred tipped the meek flower and poured
        its amber nectar into four containers. His vision blurred and his nose
        leaked onto his fine moustache as he stuffed his satchel and raced away
        before the confusion set in.
      </p>
      <p>
        Thick tree limbs overlapped amid the jungle layers, and provided stable
        footing. Aldred's sinuses cleared as he distanced himself from the
        blooming flower. He slipped between spears of branches and blades of
        leafs with fluid mobility. At times he would pick up speed during a
        brief fall and heard the wind whistle into his ears. Here he found a
        modicum of control in his life, where he could decide the dangers he
        would risk. Grinning, he accelerated his pace, gripping smaller branches
        and allowing for less predictable outcomes that he reflexively managed.
        The flow injected a moment of delight into his day, but the levity felt
        hollow, tainted by the uncertainty of change.{" "}
        <Thought>I'm leaving this, for something I will also enjoy,</Thought>{" "}
        Aldred reassured himself. He continued to travel faster just up until
        Tezerald's threshold of objection.
      </p>
      <p>
        Aldred's brief indulgence ended as he slowed down to drink from the
        small pools of rainwater that remained on some larger leafs. As he
        quenched his thirst, he inspected the nearby crooks where trunks met
        boughs. He passed on beetles, poisonous silver flowers and an abandoned
        nest. Just before moving on, he found a small collection of mature brown
        mushrooms in a mossy pit.{" "}
        <AldredSpeach>"Tez I found some rhadrin caps!"</AldredSpeach> Aldred
        exclaimed and withdrew a small bone dagger from behind his satchel. He
        sawed through the stiff root in the hope that they would have regrown by
        the time he returned. Eyes closed, he savored the rich earthen scent.
        The smell filled him with a vague collection of pleasant moments with
        his parents that left him as soon as he tried to isolate one.
      </p>
      <p>
        <TezSpeach>"Wonderful. Are you done yet?"</TezSpeach> Tezerald asked
        sounding irritated.{" "}
        <TezSpeach>"You should be leaving shortly."</TezSpeach>
      </p>
      <p>
        <AldredSpeach>"Yes, very soon."</AldredSpeach> Aldred cut a notch off
        the cap and popped it into his mouth.
        <AldredSpeach>"Exactly how far away is the conduit?"</AldredSpeach>
      </p>
      <p>
        <TezSpeach>
          "Very far, in a desert northwest. There will be little food, so pack
          that up and expect to save whatever provisions will last."
        </TezSpeach>
      </p>
      <p>
        Aldred's mouth watered as he chewed away at the rubbery treat.{" "}
        <AldredSpeach>"Carrying food sounds tiresome,"</AldredSpeach> he said as
        he knocked away the larger clumps of dirt from the mushrooms. The aroma
        and motions sparked an unexpected pang of remorseful nostalgia.{" "}
        <Thought>Happy twentieth birthday,</Thought> he thought with
        disappointment, and reluctantly stowed the rhadrin caps away for a
        unknown day. Aldred gazed at the nourishment-stocked maze of freedom.{" "}
        <Thought>I don't want to leave.</Thought>
      </p>
      <p>
        <TezSpeach>"Your heart is slowing,"</TezSpeach> Tezerald interrupted
        Aldred's melancholy.{" "}
        <TezSpeach>"Stop whining about needs to be done."</TezSpeach> A pop
        echoed up from the jungle's floor, followed by shriek. Aldred tried to
        enjoy the morsel and did not bother looking down for the vikar root's
        victim.{" "}
        <TezSpeach>
          "I feel panic; something down there is dying. Things are dying
          everywhere, but not you. I'll keep you safe and your body healthy. You
          just need to stay away from people and do as you're told."
        </TezSpeach>
      </p>
      <p>
        Aldred nodded as he was reminded of his blessings.{" "}
        <AldredSpeach>"You're right,"</AldredSpeach> Aldred said and swallowed
        before he had finished extracting all its flavor.{" "}
        <AldredSpeach>"Okay let's get going."</AldredSpeach>
      </p>
      <p>
        <TezSpeach>"Before you do, you will meditate one more time."</TezSpeach>
      </p>
      <p>
        Aldred's stomach sunk, toyed with beyond his understanding.{" "}
        <Thought>All I do is disappoint you,</Thought> he thought.{" "}
        <AldredSpeach>
          "But I'm already leaving for the conduit. You said it will fix my
          problems."
        </AldredSpeach>
      </p>
      <p>
        <TezSpeach>
          "Wrong. I said it will help you, and that is no excuse to fall out of
          practice. Secure yourself, now. This is as good a place as any."
        </TezSpeach>
      </p>
      <p>
        Suppressing a sigh, Aldred uncoiled a braided vine from his waist and
        whipped it around the tree. <AldredSpeach>"Okay Tez,"</AldredSpeach> he
        said as he plopped down and tied himself to the trunk at his back.
        Aldred withdrew Tezerald from the ornate sheath hanging at his side. The
        jagged sword had no discernable edge and resembled a narrow breathing
        flame metallised. He could pass as steel at a glance, but his purple hue
        and unusual make would raise questions, or so he warned. From his
        friend, Aldred caught fragments of his reflection: his soft unkempt
        beard, his brown matted hair and weary hazel eyes. He could no longer
        imagine his father looking any different.
      </p>
      <p>
        The final droplets of rain tapped from the pointed tips of rustling
        leafs. Insects whirred and birds sang. Sweet pollen and clusters of
        puffy seeds drifted by. The ensnared creature below whimpered for mercy.
        Aldred shut them out, and focused on the constant boon he relied upon.
        He exhaled with defeated complacency, as he laid his friend down before
        him. <Thought>Reality is what you make it to be,</Thought> Aldred
        recited Tezerald's mantra.
      </p>
      <p>
        Aldred closed his eyes and let the dark envelop his senses. He walked a
        narrow pathway deep into himself, where indiscernible thoughts played
        with the horizon of rationality. A rumbling barrier raced toward him,
        growling and roaring. The air hummed against his dirtcovered skin, and
        rattled the core of his chest. Walls crashed within his mind, in a
        half-controlled leap of intoxication; to escape and discover.
      </p>
      <br />
      <p>
        Aldred opens his eyes and Tezerald is there, sharp and grey. Only the
        dark plants remain of all the life that bustled about. Scentless pollen
        rides the wind he cannot feel. The world is silent, devoid of creatures
        and color. He feels drawn in all directions, but remains
        everdisciplined: sitting, bound and safe.
      </p>
      <p>
        Within Adlred's open palm he imagines it: the warmth, the yellow light,
        the murmur of a flame. He wishes it would come to him as he imagines it,
        as Tezerald says should have come some time ago, but he cannot spark his
        will into this world. Aldred focuses harder, grappling the mystery with
        tinkerings of premises and desires, but runs into dead ends from which
        he is forced to retreat. The failures wear on him as tension strains a
        rope.{" "}
        <Thought>
          He says everyone can do this. That I should be able to pull it from
          within myself and yet it seems impossible.
        </Thought>
      </p>
      <p>
        A memory comes to him, welcome yet unwanted: a struggling family risking
        fire to cook the meat of a fresh catch. The vision confuses Aldred,
        filling him with the fear of success, the longing for what was and his
        reluctance to leave. Tezerald remains, still and silent.{" "}
        <Thought>You're all I have,</Thought> Aldred thinks and touches the
        crystalline thorns. It splits his finger open, bleeding and painless.
        Aldred stares at the black blood as it dribbles from his finger tip onto
        his inert palm.{" "}
        <Thought>
          These sessions are a waste energy, fire isn't even worth making.
        </Thought>{" "}
        Annoyed, Aldred closes his eyes once more and plucks the dream away,
        dismissing it and the reality it represents. He comes back to his vivid
        senses.
      </p>
      <br />
      <p>
        <TezSpeach>"Any progress?"</TezSpeach> Tezerald asked before Aldred
        could open his eyes.
      </p>
      <p>
        <AldredSpeach>"No,"</AldredSpeach> Aldred said and wiped the sweat from
        his forehead with his unharmed hands.{" "}
        <AldredSpeach>"I take it nothing came near me?"</AldredSpeach>
      </p>
      <p>
        <TezSpeach>"Clearly, you required no protection,"</TezSpeach> Tezerald
        said, dismissively.{" "}
        <TezSpeach>"Did you at least produce some heat?"</TezSpeach>
      </p>
      <p>
        <Thought>More disappointment.</Thought>{" "}
        <AldredSpeach>"No. I still can't feel anything."</AldredSpeach> Aldred
        untied the cord and wrapped it tight around his waist.{" "}
        <AldredSpeach>
          "Maybe this magic works differently for your people than mine. Neither
          of my parents used magic-"
        </AldredSpeach>
      </p>
      <p>
        <TezSpeach>
          "You intend to generalize the capacities of your species from a
          sampling of two persons whom you are not even sure could not summon
          fire?"
        </TezSpeach>
      </p>
      <p>
        Aldred withdrew his spined container and touched up the mud on his face.
        He felt depleted and wanted to sleep, but the sun was just rising and
        many long days of travel were yet to come.{" "}
        <AldredSpeach>"Sorry Tez, I'm just getting discouraged."</AldredSpeach>
      </p>
      <p>
        <TezSpeach>"True failure is not trying,"</TezSpeach> Tezerald said with
        authority.
        <TezSpeach>
          "Now get moving. I found some eggs young enough for you to eat."
        </TezSpeach>
      </p>
      <p>
        Without wasting time for confirmation, Aldred sheathed Tezerald and
        followed his instructions to a nearby nest with four white-spotted blue
        eggs. Unattended, he snatched the lot of them. One by one, Aldred
        crunched through the shells and swallowed the viscous yolks. Life
        consumed to sustain another's.
      </p>
      <p>
        Aldred knew that he would eventually cross beyond any point he had
        travelled, where only Tezerald could guide him back to his home. He
        ignored his stirring emotions as he climbed farther and further from
        that which he knew. However, crouched and poised to paralyze him with
        fear, hid the insurmountable doubt: that even the conduit would not
        help, and Tezerald would abandon him. Aldred carried on.
      </p>
    </Container>
  );
};
export default ChapterOne;

const Container = styled.div`
  max-width: 60ch;
  background-color: ${(props) =>
    props.dark ? `var(--bg1-dark)` : `var(--bg1-light)`};
  display: flex;
  flex-direction: column;
  margin: 0 auto 5rem auto;
  padding: 0 10px;
  @media screen and (min-width: 500px) {
    padding: 0 15px;
  }
  @media screen and (min-width: 800px) {
    padding: 0 20px;
  }
  > p {
    margin-top: 0;
    text-align: justify;
    text-justify: inter-word;
    text-indent: 2rem;
    @media screen and (min-width: 500px) {
      text-indent: 3rem;
    }
    @media screen and (min-width: 800px) {
      text-indent: 4rem;
    }
  }
`;
