import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { recalculateWindowDimensions } from "../redux/actions";

const useScreenSizeTracker = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		function setSizes() {
			dispatch(recalculateWindowDimensions())
		}
		window.addEventListener('resize', setSizes);
		return () => {
			window.removeEventListener('resize', setSizes);
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps
}
export default useScreenSizeTracker