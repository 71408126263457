import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { shiftAdditionalNavOption } from "../../redux/actions";
import styled from "styled-components";

// Helper
import navInfo from "../../helpers/navInfo";
import scrollUp from "../../helpers/scrollUp";

// Constant
import transitionTime from "../../constants/transitionTime";

// Content Imports
import TransitionContainer from "../shared/TransitionContainer";

const BodyGenerator = ({ initialPath, scaledOpacity, setScaledOpacity }) => {
  const settings = useSelector((state) => state.settings);
  const dispatch = useDispatch();
  const [hasMounted, setHasMounted] = useState(false);
  const [content, setContent] = useState(() => {
    const foundNav = navInfo
      .filter((option) => !option.fallback)
      .find(
        (option) =>
          initialPath.includes(option.path.substring(1)) ||
          initialPath.includes(option.altPath)
      );
    return foundNav
      ? foundNav.component({ initialPath })
      : navInfo.find((option) => option.fallback).component();
  });

  const addToNavIfNotThere = () => {
    if (!settings.navOptions.includes(settings.callToDisplay)) {
      dispatch(shiftAdditionalNavOption(settings.callToDisplay));
    }
  };

  useEffect(() => {
    if (!hasMounted) return;
    setScaledOpacity(0);
    
    const timeOut = setTimeout(() => {
      scrollUp();
      setScaledOpacity(1);
			const foundNav = navInfo.find(option => option.navName === settings.callToDisplay);
			if (foundNav) {
				if (!foundNav.baseOption) {
					addToNavIfNotThere();
				}
				setContent(foundNav.component({}))
			} else {
				console.log(`Unknown call to display of ${settings.callToDisplay}`);
				setContent(<h3>Unknown callToDisplay.... How embarrassing.</h3>);
			}
    }, transitionTime);
    return () => clearTimeout(timeOut);
    
  }, [settings.callToDisplay, setContent]); 

  useEffect(() => {
    setScaledOpacity(1);
    setHasMounted(true);
  }, []);

  return (
    <Body dark={settings.dark} id="content-container">
      <TransitionContainer scaledOpacity={scaledOpacity}>
        {content && content}
      </TransitionContainer>
    </Body>
  );
};
export default BodyGenerator;

const Body = styled.main`
  width: 100%;
  background-color: ${(props) =>
    props.dark ? `var(--bg1-dark)` : `var(--bg1-light)`};
  min-height: calc(100vh - 35px);
  display: flex;
  flex-direction: row;
  /* align-items: center;
  justify-content: center; */
  justify-content: space-between;
  color: ${(props) => (props.dark ? `var(--text-dark)` : `var(--text-light)`)};
  border: var(--border-size) solid white;
  border-top: none;
  /* padding: 10px 10px 0px; */
  /* @media screen and (min-width: 500px) {
    padding: 15px 15px 0px;
  }
	@media screen and (min-width: 800px) {
    padding: 20px 20px 0px;
  } */
  @media screen and (min-width: 500px) {
    min-height: calc(100vh - 70px);
  }
`;
