import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import InPortView from "../../shared/InPortView";

const TopTitle = ({ title }) => {
  const settings = useSelector((state) => state.settings);
  const [inViewPort, setInViewPort] = useState(false);
  const [lettersFilledIn, setLettersFilledIn] = useState(0);
  const titleArray = title ? title.split("") : [];

  useEffect(() => {
		let timer;
    if (inViewPort) {
      setLettersFilledIn(1);
      let numberOfFilledInLetters = 0;
      function incrementLettersFilledIn() {
        numberOfFilledInLetters++;
        setLettersFilledIn(numberOfFilledInLetters);
        if (numberOfFilledInLetters < titleArray.length) {
          timer = setTimeout(incrementLettersFilledIn, 50);
        }
      }
      timer = setTimeout(incrementLettersFilledIn, 50);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [inViewPort]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container
      className="largeScalingText"
      dark={settings.dark}
      id={`${title}-Top-Title`}
      isPowered={settings.homePowerIsOn}
    >
      <InPortView
        inViewPort={inViewPort}
        setInViewPort={setInViewPort}
        elementIdInQuestion={`${title}-Top-Title`}
				altInViewDivisor = {16}
      />
      {titleArray.map((letter, index) => {
        return (
          <TitleLetter
            key={index}
            isFilled={lettersFilledIn > index}
            dark={settings.dark}
            isPowered={settings.homePowerIsOn}
          >
            {letter}
          </TitleLetter>
        );
      })}
    </Container>
  );
};
export default TopTitle;

const Container = styled.h4`
  position: absolute;
  top: 0;
  padding: 0 10px;
  /* background-color: ${(props) =>
    props.dark ? `var(--bg1-dark)` : `var(--bg1-light)`}; */
  background-color: ${(props) =>
    props.dark ? `var(--bg2-dark)` : `var(--bg2-light)`};
  border: ${(p) =>
    p.isPowered ? "10px solid var(--power2)" : "10px solid white"};
  outline: ${(props) =>
    props.dark ? `4px solid var(--bg1-dark)` : `4px solid var(--bg1-light)`};
  outline-offset: -7px;
  margin: 0 auto;
  transform: translateY(-62%);
`;

const TitleLetter = styled.span`
margin: 0 1px;
  -webkit-text-fill-color: ${(p) =>
    p.isFilled
      ? p.isPowered
        ? p.dark
          ? "var(--text-dark)"
          : "var(--text-light)"
        : "var(--power2)"
      : p.dark
      ? "var(--bg1-dark)"
      : "var(--bg1-light)"};
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-width: thin;
  -webkit-text-stroke-color: ${(p) =>
    p.isPowered
      ? "var(--power2)"
      : p.dark
      ? "var(--text-dark)"
      : "var(--text-light)"};
`;
