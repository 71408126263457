/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

// local helpers

const calcSingleMargin = (windowWidth) => {
	if (windowWidth >= 800) {
		return 20;
	} else if (windowWidth >= 500) {
		return 10;
	}
	return 5
}

const calcIdFromOptionName = (navOption) => {
	return navOption === "ABOUT" ? `${navOption} ME-nav-button` : `${navOption}-nav-button`;
}

const calcWidthFromId = (id) =>{
	const element = document.getElementById(id);
	if (element &&  element.getClientRects) {
		const rects = element.getClientRects();
		if (rects && rects[0]) {
			return rects[0].width || 0;
		}
	}
	return 0
}

// BEGINNING OF COMPONENT

const ShiftingBar = () => {
	const {navOptions, callToDisplay, windowWidth, homePowerIsOn} = useSelector((state) => state.settings);
	const [hasMounted, setHasMounted] = useState(false);
	const [singleMargin, setSingleMargin] = useState(0)
  	const [triggerRecalc, setTriggerRecalc] = useState(0); // value not used, but the re-render is needed for when a new nav-option is added
  
	useEffect(()=>{
		setHasMounted(true)
	},[])

	// This calibrates the margin size based on window width
	useEffect(()=>{
		if (singleMargin === 0) return setSingleMargin(calcSingleMargin(windowWidth))
		const timer = setTimeout(()=>{
			setSingleMargin(calcSingleMargin(windowWidth))
		},150)
		return () => clearTimeout(timer)
	},[windowWidth])

	useEffect(()=>{
		setTriggerRecalc(v=>v+1)
	}, [navOptions])

	if (!hasMounted) return null;
  
	const id = calcIdFromOptionName(callToDisplay);
	const width = calcWidthFromId(id);
	let right = singleMargin;

	let hasEncountedSelectedNavLink = false;
	navOptions.forEach((navOption)=>{
		const navOptionId = calcIdFromOptionName(navOption);
		if (hasEncountedSelectedNavLink) {
			right += 2*singleMargin + calcWidthFromId(navOptionId);
		} else {
			if (navOptionId===id) hasEncountedSelectedNavLink = true;
		}
	})

  return (
    <Bar
      isPowered={homePowerIsOn}
      width={width || 50}
      right={right}
    />
  )
};
export default ShiftingBar;

const Bar = styled.div`
  position: absolute;
  width: ${(props) => props.width}px;
  height: 1px;
  right: ${({right})=>right}px;
  opacity: ${(props) => props.translateX === 0 && "0"};
  bottom: 7px;
  background: ${(props) => (props.isPowered ? "white" : "grey")};
  transition: all 0.5s ease-in-out, opacity 0.001s;
  backface-visibility: hidden;
  @media screen and (min-width: 500px) {
    height: 2px;
    bottom: 18px;
  }
  @media screen and (min-width: 800px) {
    height: ${(props) => (props.isPowered ? "2px" : "3px")};
    bottom: 12px;
  }
`;
