import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import IconButton from "../shared/IconButton";
import { socialLinkedin } from "react-icons-kit/ionicons/socialLinkedin";
import { socialGithub } from "react-icons-kit/ionicons/socialGithub";
import createAFocusClickRemove from "../../helpers/createAFocusClickRemove";

const MyLinks = () => {
  const settings = useSelector((state) => state.settings);
  const links = [
    {
      icon: socialLinkedin,
      href: "https://www.linkedin.com/in/andrewdiles/",
      label: "My LinkedIn Profile",
    },
    {
      icon: socialGithub,
      href: "https://github.com/AndrewDiles",
      label: "My Github Profile",
    },
  ];
  return (
    <Container dark={settings.dark} className="scalingTopAndBottomMargin">
      {links.map((linkInfo, index) => {
        return (
          <IconButton
            key={index}
            icon={linkInfo.icon}
            handleClick={() => {
              createAFocusClickRemove(linkInfo.href, `link to ${linkInfo.label}-element`);
            }}
            label={`link to ${linkInfo.label}`}
          />
        );
      })}
    </Container>
  );
};
export default MyLinks;

const Container = styled.div`
  position: relative;
  /* width: 100%; */
  /* background-color: ${(props) =>
    props.dark ? `var(--bg1-dark)` : `var(--bg1-light)`}; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  > div {
    padding: 0 1rem;
		/* padding: 0; */
		/* margin: 0 1rem; */
    @media screen and (min-width: 500px) {
      padding: 0 2rem;
			/* margin: 0 2rem; */
    }
    @media screen and (min-width: 800px) {
      padding: 0 3rem;
			/* margin: 0 3rem; */
    }
  }
`;
