import styled from "styled-components";
import { useSelector } from "react-redux";
import SampleBlock from "./SampleBlock";

const AboutPentris = () => {
  const settings = useSelector((state) => state.settings);
  return (
    <Container className="centered col" isPowered={settings.homePowerIsOn}>
      <p>Ah, you've found a little easter egg, well done!</p>
      <p>
        Pentris is a side project I coded in my spare time over a couple of
        weeks. It was inspired by the classic game Tetris.
      </p>
      <p>
        Tetris, created by Alexey Pajitnov in 1984, is a simple game where
        blocks comprising of four connected cells fall. The player determines
        how to spin them and place them. If a row of the game board is ever
        full, that row is erased, and the above lines drop down to take its
        place.
      </p>
			<SampleBlock/>
      <p>
        Pentris is similar to Tetris, but with a small twist: blocks are
        comprised of five cells. This seemingly small change increased the
        game's difficulty more than I expected. To compensate, I added many
        optional features which I'm sure you'll love!
      </p>
      <p>Why not try it for yourself?</p>
    </Container>
  );
};

export default AboutPentris;

const Container = styled.section`
  flex-direction: column;
  p {
    width: 100%;
    color: ${(props) => (props.isPowered ? `var(--power2)` : `white`)};
    max-width: 45ch;
    text-align: left;
  }
  p:last-of-type {
    margin-bottom: 3em;
    width: fit-content;
  }
`;
