import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const NeonDot = ({ classNames }) => {
  const settings = useSelector((state) => state.settings);

  return (
    <Container
      dark={settings.dark}
      isPowered={settings.homePowerIsOn}
      style={{ position: "relative" }}
    >
      {settings.homePowerIsOn && (
        <GlowingSpan className={classNames}>•</GlowingSpan>
      )}
      <NonGlowingSpan isPowered={settings.homePowerIsOn} className={classNames}>
        {settings.homePowerIsOn ? "•" : "◦"}
      </NonGlowingSpan>
    </Container>
  );
};
export default NeonDot;
const Container = styled.div`
  position: relative;
  background: ${(props) =>
    !props.isPowered
      ? "inherit"
      : props.dark
      ? "-webkit-linear-gradient(-45deg, var(--text-dark), var(--power2))"
      : "-webkit-linear-gradient(-45deg, var(--text-light), var(--power2))"};
  -webkit-background-clip: ${(props) => props.isPowered && "text"};
	background-clip: ${(props) => props.isPowered && "text"};
  -webkit-text-fill-color: ${(props) => props.isPowered && "transparent"};
  margin-right: 0.5rem;
  @media screen and (min-width: 300px) {
    margin-right: 1rem;
  }
  @media screen and (min-width: 500px) {
    margin-right: 1.5rem;
  }
  @media screen and (min-width: 800px) {
    margin-right: 2rem;
  }
`;
const NonGlowingSpan = styled.span`
  display: inline-block;
  width: 0;
  color: ${(props) => props.isPowered && "#fff"};
  text-shadow: ${(props) =>
    props.isPowered && "0 0 3px #fff, 0 0 5px hsl(180, 100%, 95%)"};
  /* -webkit-background-clip: ${(props) => props.isPowered && "text"};
  -webkit-text-fill-color: ${(props) => props.isPowered && "transparent"}; */
`;

const GlowingSpan = styled.span`
  position: absolute;
  animation: neonFadeIn ease infinite 1s alternate-reverse;
  text-shadow: 0 0 7px #fff, 0 0 10px hsl(180, 100%, 95%),
    0 0 21px hsl(200, 100%, 75%), 0 0 42px hsl(200, 100%, 55%),
    0 0 60px hsl(200, 100%, 55%), 0 0 80px hsl(200, 100%, 55%);
`;
