import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setButtonType, setHomePower } from "../redux/actions";

const useSavedPreferences = () => {
    const settings = useSelector((state) => state.settings);
    const dispatch = useDispatch();

    function modifyBGColorsBasedOnPower(isOn) {
        const modifier = isOn ? -5 : 0;
        document.documentElement.style.setProperty(
            "--bg1-dark",
            `hsl(150, 5%, ${10 + modifier}%)`
        );
        document.documentElement.style.setProperty(
            "--bg2-dark",
            `hsl(150, 5%, ${15 + modifier}%)`
        );
        document.documentElement.style.setProperty(
            "--bg3-dark",
            `hsl(150, 5%, ${20 + modifier}%)`
        );
    }

    useEffect(() => {
        const potentialStoredButtonPref = JSON.parse(
            window.localStorage.getItem("buttonPref")
        );
        if (potentialStoredButtonPref) {
            dispatch(setButtonType(potentialStoredButtonPref.type));
        }
        const potentialStoredPoweredPref = JSON.parse(
            window.localStorage.getItem("powerPref")
        );
        if (potentialStoredPoweredPref) {
            dispatch(setHomePower(potentialStoredPoweredPref.homePowerIsOn));
            modifyBGColorsBasedOnPower(potentialStoredPoweredPref.homePowerIsOn);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const buttonPref = {
            type: settings.buttonNumber,
        };
        window.localStorage.setItem("buttonPref", JSON.stringify(buttonPref));
    }, [settings.buttonNumber]);

    useEffect(() => {
        const powerPref = {
            homePowerIsOn: settings.homePowerIsOn,
        };
        window.localStorage.setItem("powerPref", JSON.stringify(powerPref));
        modifyBGColorsBasedOnPower(settings.homePowerIsOn);
    }, [settings.homePowerIsOn]);

    return null;
};
export default useSavedPreferences;
