import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import * as THREE from "three";

// Much of code below was lifted from https://tympanus.net/codrops/2022/01/05/crafting-scroll-based-animations-in-three-js/

const SpaceBG = () => {
  // let tick = () => {};
	const canvas = useRef(null)
  const [tickNumber, setTickNumber] = useState(0);
  // const [savedTick, setSavedTick] = useState(() => {
  //   return () => {
  //     // console.log("initial savedTick function");
  //   };
  // });
  
  useEffect(() => {
    // const canvas = document.getElementById("space-canvas");
    const scene = new THREE.Scene();
    /**
     * Particles
     */
    // Geometry
    const particlesCount = 300;
    const positions1 = new Float32Array(particlesCount);
    const positions2 = new Float32Array(particlesCount);
    const positions3 = new Float32Array(particlesCount);

    for (let i = 0; i < particlesCount; i++) {
      positions1[i * 3 + 0] = (Math.random() - 0.5) * 10;
      positions1[i * 3 + 1] = 4 * 0.5 - Math.random() * Math.random() * 4 * 20;
      positions1[i * 3 + 2] = (Math.random() - 0.5) * 10;
      positions2[i * 3 + 0] = (Math.random() - 0.5) * 10;
      positions2[i * 3 + 1] = 4 * 0.5 - Math.random() * Math.random() * 4 * 20;
      positions2[i * 3 + 2] = (Math.random() - 0.5) * 10;
      positions3[i * 3 + 0] = (Math.random() - 0.5) * 10;
      positions3[i * 3 + 1] = 4 * 0.5 - Math.random() * Math.random() * 4 * 20;
      positions3[i * 3 + 2] = (Math.random() - 0.5) * 10;
    }

    const particlesGeometry1 = new THREE.BufferGeometry();
    particlesGeometry1.setAttribute(
      "position",
      new THREE.BufferAttribute(positions1, 3)
    );
    const particlesGeometry2 = new THREE.BufferGeometry();
    particlesGeometry2.setAttribute(
      "position",
      new THREE.BufferAttribute(positions2, 3)
    );
    const particlesGeometry3 = new THREE.BufferGeometry();
    particlesGeometry3.setAttribute(
      "position",
      new THREE.BufferAttribute(positions3, 3)
    );

    // Material
    // let initialColor = "#ffeded";
		let initialColor = "#cccccc" // 0,0,0.8 in hsl

    const particles1Material = new THREE.PointsMaterial({
      color: initialColor,
      sizeAttenuation: true,
      size: 0.02,
    });
    const particles2Material = new THREE.PointsMaterial({
      color: initialColor,
      sizeAttenuation: true,
      size: 0.015,
    });
    const particles3Material = new THREE.PointsMaterial({
      color: initialColor,
      sizeAttenuation: true,
      size: 0.03,
    });

    // Points
    const particles1 = new THREE.Points(particlesGeometry1, particles1Material);
    const particles2 = new THREE.Points(particlesGeometry2, particles2Material);
    const particles3 = new THREE.Points(particlesGeometry3, particles3Material);
    scene.add(particles1);
    scene.add(particles2);
    scene.add(particles3);

    /**
     * Lights
     */
    // const directionalLight = new THREE.DirectionalLight("#ffffff", 1);
    // directionalLight.position.set(1, 1, 0);
    // scene.add(directionalLight);

    // Handle resize, scroll and mouse movement
    const sizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
    function handleResize() {
      // Update sizes
      sizes.width = window.innerWidth;
      sizes.height = window.innerHeight;

      // Update camera
      camera.aspect = sizes.width / sizes.height;
      camera.updateProjectionMatrix();

      // Update renderer
      renderer.setSize(sizes.width, sizes.height);
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    }
    window.addEventListener("resize", handleResize);

    let scrollY = window.scrollY;
    function handleScroll() {
      scrollY = window.scrollY;
    }
    window.addEventListener("scroll", handleScroll);

    const cursor = {};
    cursor.x = 0;
    cursor.y = 0;

    function handleMouseMove(e) {
      cursor.x = e.clientX / sizes.width - 0.5;
      cursor.y = e.clientY / sizes.height - 0.5;
    }
    window.addEventListener("mousemove", handleMouseMove);

    /**
     * Camera
     */
    // Group
    const cameraGroup = new THREE.Group();
    scene.add(cameraGroup);

    // Base camera
    const camera = new THREE.PerspectiveCamera(
      35,
      sizes.width / sizes.height,
      0.1,
      100
    );
    camera.position.z = 6;
    cameraGroup.add(camera);

    /**
     * Renderer
     */
    const renderer = new THREE.WebGLRenderer({
      canvas: canvas.current,
      alpha: true,
    });
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
		// renderer.setPixelRatio(Math.min(window.devicePixelRatio, 1));

    /**
     * Animate
     */
    const clock = new THREE.Clock();
    let previousTime = 0;
    let particle1SparkleInfo = {
      time: 0,
      sparklePhase: 0,
      resetNeeded: false,
      timeBetweenSparkles: 2,
    };
    let particle2SparkleInfo = {
      time: 0,
      sparklePhase: 0,
      resetNeeded: false,
      timeBetweenSparkles: 3,
    };
    let particle3SparkleInfo = {
      time: 0,
      sparklePhase: 0,
      resetNeeded: false,
      timeBetweenSparkles: 4.5,
    };

    function resetSparkleInfoAndColor(sparkleObject, particlesClass) {
      sparkleObject.time = 0;
      sparkleObject.sparklePhase = 0;
      sparkleObject.resetNeeded = false;
      // color = "#ffeded";    
			particlesClass.material.color.setHSL(0, 0, 0.8);
    }
		// const sparkleDuration = 0.5 // in seconds
		const sparkleDuration = .8 // in seconds

		function calcS_L (phase) {
			let s = 1;
				let l = 0; // l must range from 0.8 to 0.5 and back, so max delta l is 0.3, |RoC| is 1.2 
					if (phase < sparkleDuration/2) {
						s *= phase / (sparkleDuration/2);
						l = -1.2*(phase) +0.8;
					} else {
						s *= -(phase - sparkleDuration) / (sparkleDuration/2);
						l = 1.2*(phase) +0.2;
					}
					if (s > 1) s = 1;
					if (s < 0.001) s = 0;
					if (l > 0.8) l = 0.8;
					if (l < 0.5) l = 0.5;
					return ({s,l})
		}

    function handleTimePass(sparkleObject, particlesClass, passedTime, i) {
      sparkleObject.time += passedTime;
      if (sparkleObject.time > sparkleObject.timeBetweenSparkles)
        sparkleObject.sparklePhase += passedTime;
      if (sparkleObject.sparklePhase > sparkleDuration) sparkleObject.resetNeeded = true;
      else if (sparkleObject.sparklePhase > 0) {
				const {s,l} = calcS_L(sparkleObject.sparklePhase);
				if (i === 0 ) {
					// yellow
					particlesClass.material.color.setHSL(0.167, s, l);
				} else if (i === 1) {
					// blue
					particlesClass.material.color.setHSL(0.6, s, l);
				}	else {
					// red
					particlesClass.material.color.setHSL(0, s, l);
				}
      }
    }
    function manageSparkles(arrayOfSparkInfo, arrayOfParticles, passedTime) {
      for (let i = 0; i < arrayOfSparkInfo.length; i++) {
        if (arrayOfSparkInfo[i].resetNeeded) {
          resetSparkleInfoAndColor(arrayOfSparkInfo[i], arrayOfParticles[i]);
        } else {
          handleTimePass(arrayOfSparkInfo[i], arrayOfParticles[i], passedTime, i);
        }
      }
    }

    const tick = () => {
      const elapsedTime = clock.getElapsedTime();
      const deltaTime = elapsedTime - previousTime;
      previousTime = elapsedTime;

      manageSparkles(
        [
					particle1SparkleInfo,
					particle2SparkleInfo,
					particle3SparkleInfo
				],
        [
					particles1, 
					particles2,
					particles3
				],
        deltaTime
      );

      // Animate camera
      camera.position.y = (-scrollY / sizes.height) * 4;
      const parallaxX = cursor.x * 0.5;
      const parallaxY = -cursor.y * 0.5;
      cameraGroup.position.x +=
        (parallaxX - cameraGroup.position.x) * 5 * deltaTime;
      cameraGroup.position.y +=
        (parallaxY - cameraGroup.position.y) * 5 * deltaTime;

      // Render
      renderer.render(scene, camera);

      // Call tick again on the next frame
      setTickNumber(window.requestAnimationFrame(tick));
    };
    // setSavedTick(() => {
    //   return tick;
    // });
    tick();
		// console.log(sizes.width)
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("mousemove", handleMouseMove);
			setTickNumber((currentTickNumber) => {
				cancelAnimationFrame(currentTickNumber)
			})
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
	
  // let timer;

  // useEffect(() => {
  //   if (!hasLoaded) {
  //     setHasLoaded(true);
  //     return;
  //   }
  //   cancelAnimationFrame(tickNumber + 1);
  //   if (timer) {
  //     clearTimeout(timer);
  //   }
  //   timer = setTimeout(() => {
  //     setSavedTick((currentSavedTick) => {
  //       // console.log(currentSavedTick)
  //       if (currentSavedTick) {
	// 				console.log("continuing ticks");
	// 				currentSavedTick();
	// 			} else {
	// 				console.log("error running next space tick")
	// 			}
  //     });
  //     // console.log(tick)
  //     // tick()
  //   }, transitionTime);
  // }, [settings.callToDisplay]);

  return <SpaceCanvas id="space-canvas" ref = {canvas}/>;
};
export default SpaceBG;

const SpaceCanvas = styled.canvas`
  position: fixed;
  /* top: -10vh;
  left: -10vw;
  height: 120vh;
  width: 120vw; */

  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
	/* z-index: 50; */
  /* will-change: content; */
`;
