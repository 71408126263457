import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const Block = ({ blockInfo, outerIndex, display }) => {
  const settings = useSelector((state) => state.settings);
  const possibilities = [
    [
      [false, false, false, false, false, false, false, false, true],
      [],
      [null, false, false, false, false, null, false, false, false],
      [false, false, false, false, false, false, false, true, false],
      [],
      [null, false, false, false, false, null, false, false, false],
      [],
      [false, false, false, false, false, null, null, false, false],
      [false, false, null, false, false, null, false, false, false],
    ],
    [
      [],
      [null, false, false, false, null, false, null, false, false],
      [null, false, false, false, null, null, false, false, false],
      [],
      [null, null, false, null, false, false, null, false, false],
      [],
      [false, null, false, false, null, null, null, false, false],
      [false, null, false, false, null, false, null, false, false],
      [false, false, false, false, null, null, false, false, false],
    ],
    [
      [false, false, false, false, null, null, false, false, false],
      [],
      [],
      [false, null, false, false, false, null, false, null, false],
      [false, null, false, false, false, false, null, false, false],
      [false, null, false, false, false, false, null, null, false],
      [],
      [],
      [false, null, false, false, null, false, null, null, false],
    ],
    [
      [],
      [],
      [false, false, false, null, null, false, false, false, false],
      [],
      [],
      [false, null, false, null, null, false, false, false, false],
      [false, null, false, false, null, false, false, false, false],
      [],
      [],
    ],
    [
      [],
      [],
      [],
      [false, false, false, false, true, false, false, false, false],
      [],
      [],
      [false, false, false, false, null, false, null, false, false],
      [],
      [],
    ],
    [
      [false, false, false, null, null, false, false, false, null],
      [false, false, false, false, false, false, true, false, false],
      [false, false, false, null, null, false, null, false, false],
      [false, null, false, null, null, false, false, false, false],
      [],
      [null, null, false, null, null, false, false, false, false],
      [false, null, false, false, null, false, false, null, false],
      [],
      [false, null, false, false, null, false, false, null, false],
    ],
    [
      [],
      [],
      [false, null, false, false, null, false, false, false, false],
      [],
      [false, false, false, false, false, null, false, false, null],
      [],
      [false, null, false, false, null, false, false, false, false],
      [false, false, false, false, false, true, false, false, false],
      [],
    ],
    [
      [false, null, false, false, null, false, false, false, null],
      [null, null, false, false, null, false, false, false, false],
      [null, false, false, false, null, false, false, false, false],
      [false, null, false, false, false, null, false, false, null],
      [],
      [false, false, null, false, false, null, false, false, false],
      [],
      [null, null, false, false, null, false, false, false, false],
      [],
    ],
    [
      [],
      [],
      [],
      [false, null, null, null, false, false, false, false, false],
      [],
      [false, null, false, null, false, false, false, false, false],
      [false, null, null, false, false, false, false, false, false],
      [null, null, false, false, false, false, false, false, false],
      [],
    ],
  ];
  return (
    <ThreeByThreeGrid>
      {blockInfo.map((value, index) => {
        if (display === 1) {
          return (
            <Cell
              className="centered"
              type="number"
              key={index}
              index={index}
              outerIndex={outerIndex}
              display={display}
              min="1"
              step="1"
              max="9"
            >
              {value}
            </Cell>
          );
        } else {
          return value ? (
            <Cell
              className="centered"
              type="number"
              key={index}
              index={index}
              outerIndex={outerIndex}
              display={display}
              min="1"
              step="1"
              max="9"
            >
              {value}
            </Cell>
          ) : (
            <PossibleValues key={index}>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((value, possibleIndex) => {
                return (
                  <Possibility
                    key={possibleIndex}
                    isPowered={settings.homePowerIsOn}
                    isSafari={settings.isSafari}
                    className="centered"
                    potential={possibilities[outerIndex][index][possibleIndex]}
                  >
                    {value}
                  </Possibility>
                );
              })}
            </PossibleValues>
          );
        }
      })}
    </ThreeByThreeGrid>
  );
};
export default Block;

const ThreeByThreeGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border: 1px solid;
`;
const Cell = styled.div`
  height: 17px;
  width: 17px;
  border: 1px dashed;
  border-color: ${(p) =>
    p.display === 1 && p.index === 0 && p.outerIndex === 0 && "var(--power1)"};
  background: ${(p) =>
    p.display === 1 &&
    p.index === 0 &&
    p.outerIndex === 0 &&
    "radial-gradient(var(--bg3-dark), var(--power1))"};
  @media screen and (min-width: 300px) {
    height: 22px;
    width: 22px;
  }
  @media screen and (min-width: 500px) {
    height: 30px;
    width: 30px;
  }
  @media screen and (min-width: 800px) {
    height: 40px;
    width: 40px;
  }
`;
const PossibleValues = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 17px;
  width: 17px;
  border: 1px dashed;
  @media screen and (min-width: 300px) {
    height: 22px;
    width: 22px;
  }
  @media screen and (min-width: 500px) {
    height: 30px;
    width: 30px;
  }
  @media screen and (min-width: 800px) {
    height: 40px;
    width: 40px;
  }
`;
const Possibility = styled.div`
  font-size: 4px;
  color: ${(p) =>
    p.potential ? "lime" : p.potential === null ? "grey" : "red"};
  -webkit-text-fill-color: ${(p) =>
    p.isPowered
      ? !p.isSafari
        ? p.potential
          ? "lime"
          : p.potential === null
          ? "grey"
          : "red"
        : ""
      : ""};
  @media screen and (min-width: 300px) {
    font-size: 5px;
  }
  @media screen and (min-width: 500px) {
    font-size: 7px;
  }
  @media screen and (min-width: 800px) {
    font-size: 9px;
  }
`;
