// React imports
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

// Local imports
import ProjectSection from "../ProjectSection";
import EasyExample from "./EasyExample";

// Helper imports
import createNumberedArray from "../../../../helpers/createNumberedArray";

const Approach = () => {
  const settings = useSelector((state) => state.settings);

  return (
    <ProjectSection title="Approach">
      <p>
        At first, I figured there would be four <strong>Stages</strong> of
        progression:
      </p>
      <ol>
        <li>Make a grid of inputs</li>
        <li>Calculate possible entries</li>
        <li>Test for exclusive possibilities</li>
        <li>Insert new known values and repeat until complete</li>
      </ol>
      <p>
        <strong>Stage 1</strong> was straight forward:
      </p>
      <ThreeByThreeGrid>
        {createNumberedArray(9).map((value, index) => {
          return (
            <CellInput
              className="centered"
              defaultValue={value + 1}
              type="number"
              key={index}
              min="1"
              step="1"
              max="9"
            />
          );
        })}
      </ThreeByThreeGrid>
      <p>
        Well... sort of. Right away I realized I also had to test if the entries
        were legal, as algorithms will break if the entries aren't legal
        integers.{" "}
        <em>
          Note: Testing the legality of entries can either be defined as the
          test for current contradictions, or testing for future contradictions.
          The former is fast, and was opted for, since the latter presumes I've
          already finished a solver.
        </em>
      </p>
      <p>
        Once that was handled, <strong>Stage 2</strong> invovled keeping track
        of what numbers we knew a cell was, and what numbers we knew cells could
        not be. This involved writing three algorithms:
      </p>
      <ol>
        <li>One for rows</li>
        <li>One for columns</li>
        <li>One for 3x3 blocks</li>
      </ol>
      <p>
        Each algorithm would look at each cell in the respective spaces and see
        what information they contained. Let's use an example for clarification:
      </p>
      <EasyExample display={1} />
      <p>
        In the above example, let's look a the top left most cell (column 1, row
        1, shaded{" "}
        <GreenEm
          isPowered={settings.homePowerIsOn}
          isSafari={settings.isSafari}
        >
          green
        </GreenEm>
        ). We don't currently know the value of this cell, but we have a lot of
        information about what it can't be:
      </p>
      <p>
        If you run down its column, you see that it can't be the numbers:
        1,3,4,6 or 7. This only leaves, 2, 5, 8 or 9 as possibilities.
      </p>
      <p>
        Looking along its row, we see that is also can't be a 2, or an 8. So we
        deduce that this cell has to contain either a 5 or a 9.
      </p>
      <p>
        Finally, looking inside the 3x3 cell, of which is a member, you will
        note that the center cell contains a 5. So we know the only remaining
        number it can be is a 9.
      </p>
      <p>
        That's it for <strong>Stage 2</strong>. <strong>Stage 3</strong> is a
        simple matter of testing if any cells only have one possibility.
      </p>
      <p>
        Obviously, this process would be tedious and painstaking to do for every
        cell - as a human, but a computer calculates this nearly
        instantaneously.
      </p>
      <EasyExample display={2} />
      <p>
        <strong>Stage 4</strong>: we insert the numbers and keep going... But I
        was bound to run into some issues.
      </p>
    </ProjectSection>
  );
};
export default Approach;

const ThreeByThreeGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;
const CellInput = styled.input`
  height: 17px;
  width: 17px;
  @media screen and (min-width: 300px) {
    height: 22px;
    width: 22px;
  }
  @media screen and (min-width: 500px) {
    height: 30px;
    width: 30px;
  }
  @media screen and (min-width: 800px) {
    height: 40px;
    width: 40px;
  }
`;
const GreenEm = styled.em`
  color: var(--power1);
  -webkit-text-fill-color: ${(p) =>
    p.isPowered && !p.isSafari && "var(--power1)"};
`;
