import { useEffect } from "react";
import { useSelector } from "react-redux";

const useFaviconModifier = () => {
    const settings = useSelector((state) => state.settings);

    useEffect(() => {
        try {
            const icon = document.querySelector("link[rel='icon']");
            if (icon) icon.remove();
            const shortcut = document.querySelector("link[rel='shortcut icon']");
            if (shortcut) shortcut.remove();
            document.querySelector("head").insertAdjacentHTML('beforeend', `<link rel="icon" href="/favicon${settings.homePowerIsOn ? "" : "Not"}Powered64.png" type="image/png">`);
        } catch (err) {
            console.log({ err })
        }
    }, [settings.homePowerIsOn])
};
export default useFaviconModifier;
