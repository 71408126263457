import scrollUp from "./scrollUp";

function scrollToElement({
  elementId,
  windowWidth,
	additionalModifier
}) {
  if (!elementId) {
    console.log("error - no elementId provided to scrollToElement function");
    return;
  }
  const targetElement = document.getElementById(elementId);
  if (!targetElement) {
    console.log(`Failed to grab ${elementId} by Id`);
    return;
  }
  const rect = targetElement.getBoundingClientRect();
	if (rect) {
		let topModifier = 55;
		if (windowWidth > 499) {
			topModifier = 110;
		}
		if  (additionalModifier) {
			topModifier += additionalModifier
		}
		// document.body.scrollTop = rect.top + window.pageYOffset - topModifier;
    // document.documentElement.scrollTop = rect.top + window.pageYOffset - topModifier;
		scrollUp("smooth", rect.top + window.pageYOffset - topModifier)
	}
}
export default scrollToElement;

// Old method looked like:
// elem.scrollIntoView({
//   block: "center",
//   behavior: "smooth",
// });