import React, { useState } from "react";
import styled from "styled-components";

import AngledButton from "../../shared/AngledButton";

const PlayButton = () => {
  const [isLoading, setIsLoading] = useState(false);
	
  return (
    <ButtonContainer className="centered">
      <AngledButton
        className="centered"
        label="Play Pentris"
        innerText={isLoading ? "Loading" : "Play Pentris"}
        transformOrigin="center"
        disabled={isLoading}
        handleClick={() => {
          setIsLoading(true);
          import("../../../helpers/destroyReactAndPlayPentris").then((imported) =>
            imported.default()
          );
        }}
      />
    </ButtonContainer>
  );
};

export default PlayButton;

const ButtonContainer = styled.div`
  div {
    transform: scale(1.5);
    @media screen and (min-width: 500px) {
      transform: scale(2);
    }
    @media screen and (min-width: 800px) {
      transform: scale(2.5);
    }
  }
`;
