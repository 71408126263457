// React imports
import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";


// Local imports
import demoArray from "./demoInfo";
import Header from "./Header";
import Demo from "./Demo";
import EasterEggFooter from "../About/EasterEggFooter";

const Portfolio = () => {
  const settings = useSelector((state) => state.settings);
  const [ViewProject, setViewProject] = useState(null);

  return (
    <>
      <Container dark={settings.dark} id="portfolio">
        <Header />
        {demoArray.map((demoInfo, index) => {
          return (
            <Demo
              key={index}
              demoInfo={demoInfo}
              viewProject={ViewProject}
              setViewProject={setViewProject}
            />
          );
        })}
      </Container>
      <EasterEggFooter />
    </>
  );
};
export default Portfolio;

const Container = styled.div`
  width: 100%;
  background-color: ${(props) =>
    props.dark ? `var(--bg1-dark)` : `var(--bg1-light)`};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 2rem;
`;
