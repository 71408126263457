// React and State Components
import React from "react";

// UI Components
import styled from "styled-components";

// Content Components
import Spool from "./Spool";

const Spools = ({
  isPlaying,
  currentTime,
  maxTime,
  scale,
  baseHeight,
  baseWidth,
}) => {
  const locations = [
    { top: "44%", left: "28%" },
    { top: "44%", right: "28%" },
  ];
  return (
    <>
      {locations.map((location, index) => {
        return (
          <SpoolPlacer
            className="centered"
            key={index}
            top={location.top}
            left={location.left}
            right={location.right}
          >
            <Spool
              spoolNumber={index}
              isPlaying={isPlaying}
              scale={scale}
              baseHeight={baseHeight}
              baseWidth={baseWidth}
              fillRatio={
                index === 0
                  ? maxTime === currentTime
                    ? 0
                    : (maxTime - Math.floor(currentTime)) / maxTime
                  : maxTime === currentTime
                  ? 1
                  : 1 - (maxTime - Math.floor(currentTime)) / maxTime
              }
            />
          </SpoolPlacer>
        );
      })}
    </>
  );
};

const SpoolPlacer = styled.div`
  position: absolute;
  border-radius: 50px;
  height: 0;
  width: 0;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
`;

export default Spools;
