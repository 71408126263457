import React from "react";
import SectionContainer from "../SectionContainer";
import Entry from "./Entry";

const list = [
  {
    company: "Concordia Bootcamps",
    job: "Course Content Creator",
    time: "Jan 2023 - Present, Remote [Montreal]",
    description1:
      "Building and improving lectures, demonstrations and step by step workshops to offer students easy to digest, technical explanations and hands on experience.",
    description2:
      "Striving for the best student experience, my contributions were engaging, relevant and concise.",
    link: {
      href: "https://concordiabootcamps.ca/courses/web-development-full-time/",
      content: "Learn more about concordia bootcamps!",
      smallContent: "https://concordiabootcamps.ca/",
      label: "link to concordia bootcamp's course info",
    },
  },
  {
    company: "Concordia Bootcamps",
    job: "Senior Web Development Instructor",
    time: "Jan 2023 - Present, Remote [Montreal]",
    description1:
      "Guiding students through a Full Stack curriculum (MERN), and helping my fellow instructors.",
    description2:
      "As the senior instructor, I am relied upon for teaching the more difficult concepts to the students, and providing additional impromptu demonstrations to bring clarity to confusion.",
    link: {
      href: "https://concordiabootcamps.ca/courses/web-development-full-time/",
      content: "Learn more about concordia bootcamps!",
      smallContent: "https://concordiabootcamps.ca/",
      label: "link to concordia bootcamp's course info",
    },
  },
  {
    company: "Concordia Bootcamps",
    job: "Web Development Instructor",
    time: "Mar 2022 - Dec 2022, Remote [Montreal]",
    description1:
      "I returned to the very course I took a couple years prior to teach aspiring web developers a Full Stack curriculum.",
    description2:
      "When I left I was but the learner...",
    link: {
      href: "https://concordiabootcamps.ca/courses/web-development-full-time/",
      content: "Learn more about concordia bootcamps!",
      smallContent: "https://concordiabootcamps.ca/",
      label: "link to concordia bootcamp's course info",
    },
  },
  {
    company: "SyncroSonic",
    job: "Software Developer",
    time: "Feb 2021 - Aug 2021, Hybrid [Montreal]",
    description1:
      "Completed a music license sales platform from the ground up.  Artists can create profiles and place licenses to their music for sale.  Those seeking music can browse through music and artists, sample music, build playlists, and purchases licenses based on use.",
    description2:
      "I coded the backend, redux, business logic, music player and most reusable components.",
    // link: {
    //   href: "https://www.syncro-sonic.com",
    //   content: "Live Site: www.syncro-sonic.com",
    // 	smallContent: "www.syncro-sonic.com",
    //   label: "link to syncro-sonic.com",
    // },
  },
  // {
  //   company: "Gouttières MDG",
  //   job: "Lead Installer",
  //   time: "April 2017 - Nov 2020, Montreal",
  //   description1:
  //     "Installation of eaves, fascia, soffit, siding, downpipes, etc.",
  //   description2: "I have worked part time for MDG post 2020.",
  // },
  // {
  //   company: "Gutter Bros",
  //   job: "Co-owner",
  //   time: "April 2012 - December 2016, Greater Toronto Area",
  //   description1: "Operated eaves / aluminum company with business partner.",
  //   description2:
  //     "Duties: Sales, estimates, advertising, installation, accounting, scheduling, driving, etc.",
  // },
];

const Experience = () => {
  return (
    <SectionContainer title="Experience">
      {list.map((info, index) => {
        return (
          <Entry
            key={index}
            info={info}
            isLast={index + 1 === list.length}
          />
        );
      })}
    </SectionContainer>
  );
};
export default Experience;
