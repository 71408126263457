import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import aboutMeBanner1900 from "../../assets/images/about-me-banner-1900.jpg";
import aboutMeBanner1500 from "../../assets/images/about-me-banner-1500.jpg";
import aboutMeBanner1000 from "../../assets/images/about-me-banner-1000.jpg";
import aboutMeBanner500 from "../../assets/images/about-me-banner-500.jpg";

const Banner = ({ hasMounted }) => {
  const settings = useSelector((state) => state.settings);
  const [image1900IsLoaded, setImage1900IsLoaded] = useState(false);
  const [image1500IsLoaded, setImage1500IsLoaded] = useState(false);
  const [image1000IsLoaded, setImage1000IsLoaded] = useState(false);
  const [image500IsLoaded, setImage500IsLoaded] = useState(false);
  const alt = "That's me - coding on my laptop"

  return !hasMounted ? (
    <BannerImg as="div" />
  ) : settings.windowWidth >= 1500 ? (
    <BannerImg
      isPowered={settings.homePowerIsOn}
      imageIsLoaded={image1900IsLoaded}
      onLoad={() => {
        setImage1900IsLoaded(true);
      }}
      src={aboutMeBanner1900}
      alt={alt}
    />
  ) : settings.windowWidth >= 1000 ? (
    <BannerImg
      isPowered={settings.homePowerIsOn}
      imageIsLoaded={image1500IsLoaded}
      onLoad={() => {
        setImage1500IsLoaded(true);
      }}
      src={aboutMeBanner1500}
      alt={alt}
    />
  ) : settings.windowWidth >= 500 ? (
    <BannerImg
      isPowered={settings.homePowerIsOn}
      imageIsLoaded={image1000IsLoaded}
      onLoad={() => {
        setImage1000IsLoaded(true);
      }}
      src={aboutMeBanner1000}
      alt={alt}
    />
  ) : (
    <BannerImg
      isPowered={settings.homePowerIsOn}
      imageIsLoaded={image500IsLoaded}
      onLoad={() => {
        setImage500IsLoaded(true);
      }}
      src={aboutMeBanner500}
      alt={alt}
    />
  );
};
export default Banner;
const BannerImg = styled.img`
  width: 100%;
  background-image: linear-gradient(45deg, var(--bg3-dark), var(--bg1-dark));
  background-size: ${(p) => p.imageIsLoaded && "cover"};
  overflow: hidden;
  object-position: 100% 10%;
  height: 40vh;
	filter: ${(p) => (p.imageIsLoaded ? "opacity(100%)" : "opacity(50%)")};
  transition: filter 1s ease-in-out;
	animation: ${p => !p.imageIsLoaded ? "backgroundCoverExpansion infinite 1s alternate" : p.isPowered ? "aboutPoweredBanner infinite 8s" : ""};
  object-fit: cover;
  z-index: 10;
  @media screen and (min-width: 500px) {
    object-position: 100% 10%;
    height: 50vh;
		width: 90%;
		margin: auto;
  }
  @media screen and (min-width: 800px) {
    object-position: 100% 15%;
    height: 60vh;
		width: 75%;
		
  }
  @media screen and (min-width: 1400px) {
    object-position: 100% 20%;
    height: 70vh;
		width: 60%;
  }
`;
