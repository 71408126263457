import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import TopTitle from "./TopTitle";

const ProjectSection = ({ title, children }) => {
  const settings = useSelector((state) => state.settings);

  return (
    <Container
      dark={settings.dark}
      isPowered={settings.homePowerIsOn}
      isSafari={settings.isSafari}
			aria-labelledby={`${title}-Top-Title`}
			id = {title}
    >
      <TopTitle
        className="largeScalingText"
        dark={settings.dark}
        title={title}
      />
      {children}
    </Container>
  );
};
export default ProjectSection;

const Container = styled.section`
  width: 95%;
  max-width: 1000px;
  background-color: ${(props) =>
    props.dark ? `var(--bg2-dark)` : `var(--bg2-light)`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: justify;
  position: relative;
  border: ${(p) =>
    p.isPowered ? "10px solid var(--power2)" : "10px solid white"};
  outline: ${(props) =>
    props.dark ? `4px solid var(--bg1-dark)` : `4px solid var(--bg1-light)`};
  outline-offset: -7px;
  padding: 0.75rem 0.25rem;
  margin-bottom: 6rem;
	@media screen and (min-width: 300px) {
    padding: 1rem 0.25rem;
  }
  @media screen and (min-width: 500px) {
    padding: 1.5rem 0.5rem;
    margin-bottom: 9rem;
  }
  @media screen and (min-width: 800px) {
    padding: 2rem 1rem;
    margin-bottom: 12rem;
  }
  background: ${(p) =>
    p.isPowered &&
    !p.isSafari &&
    "-webkit-radial-gradient(white, var(--power2))"};
  -webkit-background-clip: ${(p) => p.isPowered && !p.isSafari && "text"};
  -webkit-text-fill-color: ${(p) =>
    p.isPowered && !p.isSafari && "transparent"};
  a,
  button {
    -webkit-text-fill-color: ${(p) =>
      p.isPowered && !p.isSafari && "var(--power3)"};
  }
  a:hover,
  button:hover {
    -webkit-text-fill-color: ${(p) =>
      p.isPowered && !p.isSafari && "var(--hover)"};
  }
  a:focus,
  button:focus {
    -webkit-text-fill-color: ${(p) =>
      p.isPowered && !p.isSafari && "var(--focus)"};
  }
  a:active,
  button:active {
    -webkit-text-fill-color: ${(p) =>
      p.isPowered && !p.isSafari && "var(--focus)"};
  }
`;
