// React imports
import React from "react";

// Shared imports
import ALink from "../../../shared/ALink";
import Emoji from "../../../shared/Emoji";

// Local imports
import ProjectSection from "../ProjectSection";
import ProjectSectionUlRenderer from "../ProjectSectionUlRenderer";

const Resolution = () => {
  return (
    <ProjectSection title="Resolution">
      <p>
        I built an algorithm to make a new potential puzzle assuming an unknown
        cell was a possible value. I would run this over each cell for each
        value said cell could be, until I solved the puzzle, hit a
        contradiction, or could proceed no further. If the puzzle was solved, we
        are done. If the puzzle encounters a contradiction, then we gained
        information and can try the other simpler algorithms...
      </p>
      <p>
        However, the hardest puzzles often provided no new information even
        brute forcing... But I was only doing so at one level of guesswork. I
        implented the guess algorithm inside its own potential puzzle; now
        effectively guessing inside guesses - <em>(puzzleception?)</em>, and the
        hardest puzzles I could find were all being solved.
      </p>
      <p>
        <Emoji>🎉</Emoji>
        {"  "}I had done it, every problem was being solved!{"  "}
        <Emoji>🎉</Emoji>
      </p>
      <p className="noBottomMargin">
        The solver was extremely unpolished, but I had course work to do, so I
        stopped working on it. Two years later I revisited this project and
        improved the UI by adding:
      </p>
      <ProjectSectionUlRenderer
        list={[
          "color theme selection",
          "lit up cells",
          "improved keyboard navigation",
          "improved labeling for screen readers",
          "buttons to enter numbers"
        ]}
      />
      <p className="noBottomMargin">
        I also fixed some bugs and improved the functionality by adding:
      </p>
      <ProjectSectionUlRenderer
        list={[
          "navigating through the history of changes",
          "the ability to remove numbers",
          "functional bails to improve solve time",
        ]}
      />
      <p>
        Some issues still remain - like if you try to solve a problem with very
        few knowns, my algorithm will attempt to determine a solution until the
        browser stops it from using resources, but I'm quite happy with where it
        has ended up.
      </p>
      <p>
        If you would like to see the original solver that I made in 8 days, you
        can see it{" "}
        <ALink
          href="https://dilessudoku.firebaseapp.com/"
          newTab={true}
          label="link to dilessudoku.firebaseapp.com"
          content="here"
          // paddingLeft="10px"
        />
        .
      </p>
    </ProjectSection>
  );
};
export default Resolution;
