// React and State Components
import React from "react";

// UI Components
import styled from "styled-components";
// import BottomButtons from '../../assets/viaSearch/MusicAsset/BottomButtons';

const PlasticCase = ({ scale, baseWidth }) => {
  return (
    <Case scale={scale} className="columnFlexStart">
      <BottomSection baseWidth={baseWidth} scale={scale} />
      <Top borderRadius={scale*15}/>
      <MiddleSections className="betweenFlex">
        <MidLeft />
        <MidRight />
      </MiddleSections>
			<Bottom borderRadius={scale*15}/>
      {/* <BottomButtons /> */}
    </Case>
  );
};

const Case = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: ${(props) => props.scale * 20}px;
  border: ${(props) => `${props.scale * 5}px solid var(--blue-light)`};
  /* overflow: hidden; */
`;
const BottomSection = styled.div`
  position: absolute;
  height: 0;
  width: ${(props) => 0.75 * props.baseWidth * props.scale}px;
  bottom: 0;
  /* bottom: ${(props) => props.scale * 5}px; */
  /* left: ${(props) =>
    props.scale * 5 + 0.1625 * props.baseWidth * props.scale}px; */
  left: ${(props) => 0.115 * props.baseWidth * props.scale}px;
  border-bottom: ${(props) =>
    `${0.145 * props.baseWidth * props.scale}px solid ${`var(--baby-blue)`}`};
  border-left: ${(props) =>
    `${0.035 * props.baseWidth * props.scale}px solid transparent`};
  border-right: ${(props) =>
    `${0.035 * props.baseWidth * props.scale}px solid transparent`};
`;

const Top = styled.div`
  height: 33%;
  width: 100%;
  opacity: 0.4;
  background-color: var(--baby-blue);
	border-radius: ${(p) => `${p.borderRadius}px ${p.borderRadius}px 0 0`};
`;
const MiddleSections = styled.div`
  height: 20%;
  width: 100%;
`;
const Bottom = styled.div`
  height: 47%;
  width: 100%;
  opacity: 0.4;
  background-color: var(--baby-blue);
	border-radius: ${(p) => `0 0 ${p.borderRadius}px ${p.borderRadius}px`};
`;
const MidLeft = styled.div`
  height: 100%;
  width: 35%;
  opacity: 0.4;
  background-color: var(--baby-blue);
`;
const MidRight = styled.div`
  height: 100%;
  width: 35%;
  opacity: 0.4;
  background-color: var(--baby-blue);
`;
export default PlasticCase;
